<template>

    <div class="mb-12 mx-6">

        <div
            class="flex justify-center w-full h-16 bg-center bg-contain bg-no-repeat max-w-sm mx-auto opacity-80"
            :style="`background-image: url(/images/logo.jpg)`"
            data-test="logo"
        />
        <h2 class="text-2xl text-center mb-6 pt-2 pb-6 border-b-2 text-gray-500 font-semibold">Estimate Generator</h2>

        <!-- Job Details Inputs -->
        <DetailDrawer title="Job Details" :inputs="estimate.jobDetails" :inputData="inputs" :expandedValue="true" />

        <!-- Measurements/Inputs -->
        <h2 class="text-center sm:text-lg uppercase font-semibold opacity-80 tracking-wider text-gray-500 mt-2 mx-4 my-4">Measurements &amp; Inputs</h2>
        <DetailDrawer title="Dimensions" :inputs="estimate.dimensions" :small="true" :inputData="inputs" />
        <DetailDrawer title="Structures" :inputs="estimate.structures" :small="true" :inputData="inputs" />
        <DetailDrawer title="Materials" :inputs="estimate.materials" :small="true" :inputData="inputs" />
        <DetailDrawer title="Additional Details" :inputs="estimate.details" :small="true" :inputData="inputs" />

        <!-- Estimate Tables Div -->
        <h2 class="text-center sm:text-lg uppercase font-semibold opacity-80 tracking-wider text-gray-500 mt-2 mx-4 my-4">Estimates</h2>
        <!-- Materials Estimate Table -->
        <!-- <DetailDrawer :title="`Materials ${ materialsEstimateTotal?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }`" :table="materialItemsTable"> -->
        <DetailDrawer :title="`Materials ${ (materialsEstimateTotal||0)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }`">
            <div v-if="availableMaterials?.length && materialsEstimate?.length" class="sm:py-4">
                <div class="w-full overflow-x-scroll sm:overflow-x-hidden border sm:border-none">
                    <table class="text-sm text-gray-600 text-center mx-auto sm:border w-full max-w-4xl min-w-[40rem]">
                        <!-- Table Header -->
                        <thead class="text-m uppercase text-gray-800">
                            <tr class="border-b bg-gray-100 border-gray-300 divide-x">
                                <th class="p-2 w-36 sticky-first-column">Item</th>
                                <th class="p-2 w-72">Type</th>
                                <th class="p-2 w-24">Quantity</th>
                                <th class="p-2 w-24">Unit</th>
                                <th class="p-2 w-24">Price</th>
                                <th class="p-2 w-24">Total</th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-300">
                            <tr v-for="item in materialsEstimate" :key="item.item" class="divide-x hover:bg-gray-100">
                                <!-- Item -->
                                <td class="p-2 bg-white text-gray-800 text-left font-semibold sticky-first-column">{{ item.item.toTitleCase('camel') }}</td>
                                <!-- Type -->
                                <td class="bg-white">
                                    <!-- If a material item only has 1 type, display the type vs having a selection with a single choice -->
                                    <span v-if="availableMaterials.filter(m => m.itemId === item.item).length < 2" class="p-2">
                                        {{ availableMaterials.find(m => m.itemId === item.item)?.type }}
                                    </span>

                                    <!-- If the item is shingles, don't display the dropdown choice; logic moved to jobDetails -->
                                    <!-- <span v-else-if="item.name === 'Shingles'" class="p-2">{{ itemModel.type }}</span> -->
                                    <span v-else-if="item.item === 'shingles'" class="w-full">
                                        <select v-model="estimate.jobDetails.shingleChoice" class="p-2 px-6 text-center bg-white hover:border-blue-500 border border-white w-full" style="white-space: normal;">
                                            <option v-for="material in availableMaterials.filter(m => m.itemId === item.item)" :key="material.id" :value="material.id">{{ material.type }}</option>
                                        </select>
                                    </span>

                                    <!-- display the selection drop down for multiple choice item types -->
                                    <span v-else class="w-full">
                                        <select v-model="estimate.materialItems[item.item]" class="p-2 px-6 text-center bg-white hover:border-blue-500 border border-white w-full" style="white-space: normal;">
                                            <option v-for="material in availableMaterials.filter(m => m.itemId === item.item)" :key="material.id" :value="material.id">{{ material.type }}</option>
                                        </select>
                                    </span>
                                </td>
                                <!-- Quantity -->
                                <td class="p-2 bg-white">{{ item.quantity }}</td>
                                <!-- Unit -->
                                <td class="p-2 bg-white">{{ item.unit }}</td>
                                <!-- Unit Price -->
                                <td class="p-2 bg-white">{{ item?.unitPrice?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
                                <!-- Total -->
                                <td class="p-2 bg-white">{{ item?.itemCost?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="w-full max-w-4xl mx-auto flex justify-end">
                    <div class="text-sm text-gray-600 text-center space-y-2 my-8 mr-8 sm:mr-0">
                        <div class="flex space-x-4 w-full justify-between">
                            <div class="">Subtotal</div>
                            <div class="">{{ materialsEstimateSubTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</div>
                        </div>
                        <div class="flex space-x-4 w-full justify-between">
                            <div class="">Tax</div>
                            <div class="">{{ materialsEstimateTax.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</div>
                        </div>
                        <div class="flex space-x-4 w-full justify-between">
                            <div class="">Delivery</div>
                            <div class="">{{ materialsDeliveryFee.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</div>
                        </div>
                        <div class="flex space-x-4 px-3 py-2 bg-gray-100 rounded-xl font-bold text-base -mx-3">
                            <div class="">Total</div>
                            <div class="">{{ materialsEstimateTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="flex justify-end px-10 h-16 items-center" >
                    <span class="text-gray-800 font-semibold mx-6">Total Cost</span>
                    <span class="text-gray-600 text-center border-0 rounded bg-gray-200 p-2 w-28" >{{ materialsEstimateTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</span>
                </div> -->
            </div>
        </DetailDrawer>
        <!-- Labor Estimate Table -->
        <DetailDrawer :title="`Labor ${ (laborEstimateTotal||0)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }`">
            <div v-if="laborEstimate?.length">
                <div class="w-full overflow-x-scroll sm:overflow-x-hidden sm:py-4 border sm:border-none">
                    <table class="text-sm text-gray-600 text-center mx-auto sm:border w-full max-w-4xl min-w-[35rem]">
                        <!-- Table Header -->
                        <thead class="text-m uppercase text-gray-800 border-gray-300">
                            <tr class="divide-x border-b bg-gray-100">
                                <th class="px-2 py-4 w-72 sticky-first-column">Item</th>
                                <th class="px-2 py-4 w-20">Quantity</th>
                                <th class="px-2 py-4 w-28">Unit</th>
                                <th class="px-2 py-4 w-28">Unit Price</th>
                                <th class="px-2 py-4 w-28">Total</th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-300">
                            <tr v-for="item, key in estimate.laborItems" :key="key" class="divide-x hover:bg-gray-100">
                                <!-- Item -->
                                <td class="p-2 bg-white text-gray-800 text-left font-semibold sticky-first-column">{{ laborEstimate.find(e => e.id == key).description?.toTitleCase() }}</td>
                                <!-- Quantity -->
                                <td class="p-2 bg-white">{{ laborEstimate?.find(e => e.id == key)?.quantity?.toFixed?.(2) }}</td>
                                <!-- Unit -->
                                <td class="p-2 bg-white">{{ laborEstimate.find(e => e.id == key).unit }}</td>
                                <!-- Unit Price -->
                                <td class="p-2 bg-white">{{ laborEstimate.find(e => e.id == key)?.unitPrice?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
                                <!-- Total -->
                                <td class="p-2 bg-white"><span v-if="!isNaN(laborEstimate.find(e => e.id == key)?.laborCost)">{{ laborEstimate.find(e => e.id == key)?.laborCost?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="flex justify-end px-10 h-16 items-center" >
                    <span class="text-gray-800 font-semibold mx-6">Total Cost</span>
                    <span class="text-gray-600 text-center border-0 rounded bg-gray-200 p-2 w-28" >{{ laborEstimateTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</span>
                </div>
            </div>
        </DetailDrawer>
        <!-- Estimate Worksheets -->
        <DetailDrawer title="Totals">
            <div class="grid gap-5 sm:grid-cols-2 xl:grid-cols-3 p-6">
                <div class="p-2 shadow-lg bg-white text-sm rounded-lg px-4 py-6 w-full" v-for="worksheet in worksheets" :key="worksheet.id">

                    <h2 class="text-center text-lg text-gray-800 mb-6">{{ worksheet.label }}</h2>

                    <div>
                        <div v-for="e in worksheet.estimates" :key="e.id" class="text-sm group px-4 py-2 h-full">
                            <div
                                class="flex flex-col sm:flex-row justify-between sm:items-center sm:space-x-2"
                                :class="!e.type.includes('input') ? '' : 'sm:hover:text-blue-500'"
                            >
                                <span class="uppercase font-bold opacity-40 text-xs mb-1 sm:mb-0 sm:normal-case sm:font-normal sm:opacity-100 sm:text-sm">{{ e.label }}</span>
                                <div
                                    class="w-full transition duration-300 ease-in-out rounded border border-gray-200 overflow-hidden shrink-0 sm:w-28"
                                    :class="!e.type.includes('input') ? 'bg-yellow-50 cursor' : 'bg-gray-200 hover:bg-gray-300'"
                                >

                                    <select v-if="e.type.includes('multiChoice')" v-model="estimate[worksheet.id][e.id]" class="sm:text-center px-2 py-1 w-full border-none bg-transparent">
                                        <option v-for="option in e.options" :key="option?.id || option.value" :value="option?.value ?? option">{{ option?.label || option.type }}</option>
                                    </select>
                                    <span v-else-if="!e.type.includes('input')" class="sm:text-center px-2 py-1 w-full border-none bg-transparent cursor-default">
                                        <span v-if="e.type.includes('currency')">
                                            {{ estimate[worksheet.id][e.id]?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}
                                        </span>
                                        <span v-else-if="e.type.includes('percent')">{{ (estimate[worksheet.id][e.id] * 100).toFixed(0) + '%' }}</span>
                                    </span>
                                    <input v-else :type="e.type || 'text'" v-model.number="estimate[worksheet.id][e.id]" class="sm:text-center px-2 w-full border-none bg-transparent">

                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- For reactivity -->
                    <div class="hidden">{{worksheetEstimates}}</div>

                </div>
            </div>
        </DetailDrawer>
        <!-- Commission -->
        <DetailDrawer title="Deliverables">
            <div class="grid gap-5 sm:grid-cols-2 xl:grid-cols-3 p-6">
                <div class="p-2 shadow-lg bg-white text-sm rounded-lg px-4 py-6 w-full">
                    <h2 class="text-center text-lg text-gray-800 mb-6">Commission Triggers</h2>

                    <div>
                        <div v-for="trigger in commissionTriggers" :key="trigger.id" class="text-sm group px-4 py-2 h-full">
                            <div class="flex flex-col sm:flex-row justify-between sm:items-center sm:space-x-2 sm:hover:text-blue-500">
                                <span class="uppercase font-bold opacity-40 text-xs mb-1 sm:mb-0 sm:normal-case sm:font-normal sm:opacity-100 sm:text-sm">{{ trigger.label }}</span>
                                <div
                                    class="w-full transition duration-300 ease-in-out rounded border border-gray-200 overflow-hidden shrink-0 bg-gray-200 hover:bg-gray-300 sm:w-28"
                                >
                                    <select v-if="trigger.options" v-model="estimate.commissionTriggers[trigger.id]" class="sm:text-center px-2 py-1 w-full border-none bg-transparent">
                                        <option v-for="option in trigger.options" :key="option?.id || option.value" :value="option?.value ?? option">{{ option?.label || option.type }}</option>
                                    </select>
                                    <input v-else :type="trigger.type || 'text'" v-model="estimate.commissionTriggers[trigger.id]" class="sm:text-center px-2 py-1 w-full border-none bg-transparent">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="p-2 shadow-lg bg-white text-sm rounded-lg px-4 py-6 w-full">
                    <h2 class="text-center text-lg text-gray-800 mb-6">Project Documents</h2>
                    <div class="my-3 w-full">
                        <ProjectDocs :opportunity="opportunity" docTitle="Customer Payment Sheet" :estimate="estimate" ref="customerPaymentSheet">
                            <div>
                                <div class="w-full flex justify-between mt-2 px-4">
                                    <div class="text-xs">
                                        <span class="font-semibold">Date:&nbsp;</span>
                                        <span class="text-xs">{{ today }}</span>
                                    </div>
                                    <div class="flex justify-evenly w-2/3">
                                        <div class="w-2/4 pr-1">
                                            <div class="text-xs"><span class="font-bold">Customer Deductible:</span> <span class="border-b border-black underline pb-2">${{ customerPaymentSheet.deductible }}</span></div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Customer Payment Sheet -->
                                <div class="w-full mt-8 flex mx-4 items-end">
                                    <div class="w-1/2 flex justify-between space-x-4">
                                        <div class="flex-grow font-bold border-b border-black w-full text-xs pb-4">Total Claim Amount</div>
                                        <div class="text-xs">{{ customerPaymentSheet.totalClaimAmount?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</div>
                                    </div>
                                </div>
                                <div class="w-full flex mx-4 items-end">
                                    <div class="w-1/2 flex justify-between">
                                        <div class="text-xs">Work Not Doing</div>
                                        <div class="text-xs">{{ customerPaymentSheet.workNotDoing?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</div>
                                    </div>
                                </div>
                                <div class="w-full flex mx-4 items-end">
                                    <div class="w-1/2 flex justify-between">
                                        <div class="text-xs">Additional Expenses (Upgrades)</div>
                                        <div class="text-xs">{{ customerPaymentSheet.additionalExpenses?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</div>
                                    </div>
                                </div>
                                <div class="w-full mt-8 flex mx-4 items-end">
                                    <div class="w-1/2 flex justify-between space-x-4">
                                        <div class="flex-grow font-bold border-b border-black w-full text-xs pb-4">Total Job</div>
                                        <div class="text-xs">{{ customerPaymentSheet.totalProjectCost?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</div>
                                    </div>
                                    <div class="w-1/2 pl-8 text-xs"><div class="text-xs">*Amount of first insurance check we will collect</div></div>
                                </div>
                                <div class="w-full flex mx-4 items-end">
                                    <div class="w-1/2 flex justify-between">
                                        <div class="text-xs">ACV Check</div>
                                        <div class="text-xs">-{{ customerPaymentSheet.acvCheck?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</div>
                                    </div>
                                </div>
                                <div class="w-full mt-8 flex mx-4 items-end">
                                    <div class="w-1/2 flex justify-between space-x-4">
                                        <div class="flex-grow font-bold border-b border-black w-full text-xs pb-4">Initial Balance Due</div>
                                        <div class="text-xs"><span class="border-b border-black underline pb-2">{{ customerPaymentSheet.initialBalanceDue?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</span></div>
                                    </div>
                                </div>
                                <div class="w-full mt-8 flex mx-4 items-end">
                                    <div class="w-1/2 flex justify-between space-x-4">
                                        <div class="flex-grow font-bold border-b border-black w-full text-xs pb-4">Out of Pocket Expenses</div>
                                        <div class="text-xs">{{ customerPaymentSheet.outOfPocket?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</div>
                                    </div>
                                    <div class="w-1/2 pl-8 text-xs"><div class="text-xs">*Due at contract or install depending on deposit required</div></div>
                                </div>
                                <div class="w-full mt-8 flex mx-4 items-end">
                                    <div class="w-1/2 flex justify-between space-x-4">
                                        <div class="flex-grow font-bold border-b border-black w-full text-xs pb-4">Balance Due on Contract</div>
                                        <div class="text-xs"><span class="border-b border-black underline pb-2">{{ customerPaymentSheet.balanceDue?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</span></div>
                                    </div>
                                </div>
                                <div class="w-full mt-8 flex mx-4 items-end">
                                    <div class="w-1/2 flex justify-between">
                                        <div class="flex-grow font-bold w-full text-xs">Depreciation Check</div>
                                        <div class="text-xs">{{ customerPaymentSheet.depreciation?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</div>
                                    </div>
                                    <div class="w-1/2 pl-8 text-xs"><div class="text-xs">*Final Insurance check, remaining balance collected from this</div></div>
                                </div>
                                <div class="w-full mt-8 flex mx-4 items-end">
                                    <div class="font-bold w-full text-xs">** Any approved supplements to be paid to Best Choice Roofing and will be added to the final balance</div>
                                </div>

                                <div class="w-full flex justify-end items-end mt-16 font-bold">
                                    <div class="text-xs">Customer Signature:</div>
                                    <div class="w-1/3 border-b border-black -mb-2"/>
                                    <div class="text-xs">Date:</div>
                                    <div class="w-1/5 border-b border-black -mb-2"/>
                                </div>
                            </div>
                        </ProjectDocs>
                    </div>
                    <div class="my-3 w-full">
                        <ProjectDocs :opportunity="opportunity" docTitle="Customer Quote" :estimate="estimate" @show-customer-amounts="showCustomerAmount = $event" ref="customerQuote">
                            <div>
                                <div class="w-full flex justify-between mt-2 px-4">
                                    <div class="text-xs">
                                        <span class="font-semibold">Date:&nbsp;</span>
                                        <span class="text-xs">{{ today }}</span>
                                    </div>
                                    <div class="flex justify-evenly w-2/3">
                                        <div class="w-2/4 pr-1">
                                            <div class="text-xs">Product Selection:</div>
                                            <hr class="border-black mt-2">
                                            <div class="text-xs">Style: {{ estimate.jobDetails.shingleChoice?.toTitleCase('kebab') }}</div>
                                            <div class="text-xs">Color: {{ estimate.jobDetails.shingleColor }}</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Customer Quote Table -->
                                <div class="w-full mt-8 flex justify-center">
                                    <table style="margin-left: 1rem; margin-right: 1rem;">
                                        <thead>
                                            <tr style="">
                                                <th style="border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black; border-right: 1px solid black; width: 15rem; background-color: lightgray; text-transform: uppercase; font-size: 12px; line-height: 4px; color: black; padding-top: 0px; padding-bottom: 12px;">Item</th>
                                                <th style="border-top: 1px solid black; border-bottom: 1px solid black; border-right: 1px solid black; width: 22rem; background-color: lightgray; text-transform: uppercase; font-size: 12px; line-height: 4px; color: black; padding-top: 0px; padding-bottom: 12px;">Description</th>
                                                <th style="border-top: 1px solid black; border-bottom: 1px solid black; border-right: 1px solid black; width: 15rem; background-color: lightgray; text-transform: uppercase; font-size: 12px; line-height: 4px; color: black; padding-top: 0px; padding-bottom: 12px;">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style="" v-for="v,k in customerQuote" :key="k">
                                                <!-- background-color: #71717a; color: white; text-align: left; padding-left: 0.5rem; font-weight: 600; -->
                                                <td style="vertical-align: top; text-align: left; padding-left: 0.5rem; font-weight: 600; font-size: 12px; padding-top: 12px; padding-bottom: 12px;">{{ k.toTitleCase('camel') }} <span style="float: right; padding-right: 25px; font-weight: 400;">{{ v.qty }} {{ v.unit }}</span></td>
                                                <td style="background-color: white; color: black; vertical-align: top; text-align: left; font-size: 12px; padding-top: 12px; padding-bottom: 12px;" :style="v.description.includes('**') ? 'font-size: 10px!important;' : ''">{{ v.description }}</td>
                                                <td style="background-color: white; color: black; vertical-align: top; text-align: center; font-size: 12px; padding-top: 12px; padding-bottom: 12px;" v-show="showCustomerAmount">{{  v.amount?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
                                            </tr>
                                            <tr><td class="h-8"/></tr>
                                            <!-- Total -->
                                            <tr class="">
                                                <td style="border: 0;"><div class="flex items-end space-x-4"><div style="padding-bottom: 6px;">Signature</div><div style="width: 150px; border-bottom: 1px solid black; display: inline-block;"></div></div></td>
                                                <td style="border: 0;"></td>
                                                <td style="background-color: lightgray; color: black; vertical-align: center; text-align: center; font-size: 12px; font-weight: 600; line-height: 4px; padding-top: 4px; padding-bottom: 12px;">Total:&nbsp;&nbsp;&nbsp;&nbsp;{{ estimate.cashEstimate.totalProjectCost.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </ProjectDocs>
                    </div>
                    <!-- Material Order Document -->
                    <div class="my-3 w-full">
                        <ProjectDocs :opportunity="opportunity" docTitle="Material Order" :estimate="estimate" ref="materialOrder">
                        <div>
                            <div class="w-full flex justify-between mt-2 px-4">
                                <div class="text-xs">
                                    <span class="font-semibold">Date:&nbsp;</span>
                                    <span class="text-xs">{{ today }}</span>
                                </div>
                                <div class="flex justify-evenly w-2/3">
                                    <div class="w-2/4 pr-1">
                                        <div class="text-xs">Shingle Color:</div>
                                        <hr class="border-black mt-2">
                                        <div class="text-xs">{{ estimate.jobDetails.shingleColor }}</div>
                                    </div>
                                    <div class="w-2/4 pl-1">
                                        <div class="text-xs">Material Delivery Date:</div>
                                        <hr class="border-black mt-2">
                                        <div class="text-xs">{{ estimate.jobDetails.deliveryDate }}</div>
                                    </div>
                                </div>
                            </div>
                            <!-- Materials Estimate Table -->
                            <div class="w-full mt-4 flex justify-center">
                                <table style="margin-left: 2.5rem; margin-right: 2.5rem;">
                                    <thead>
                                        <tr style="">
                                            <th style="border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black; border-right: 1px solid black; width: 9rem; background-color: #71717a; text-transform: uppercase; font-size: 12px; line-height: 4px; color: white; padding-top: 0px; padding-bottom: 12px;">Item</th>
                                            <th style="border-top: 1px solid black; border-bottom: 1px solid black; border-right: 1px solid black; width: 11rem; background-color: #71717a; text-transform: uppercase; font-size: 12px; line-height: 4px; color: white; padding-top: 0px; padding-bottom: 12px;">Type</th>
                                            <th style="border-top: 1px solid black; border-bottom: 1px solid black; border-right: 1px solid black; width: 6rem; background-color: #71717a; text-transform: uppercase; font-size: 12px; line-height: 4px; color: white; padding-top: 0px; padding-bottom: 12px;">Quantity</th>
                                            <th style="border-top: 1px solid black; border-bottom: 1px solid black; border-right: 1px solid black; width: 5rem; background-color: #71717a; text-transform: uppercase; font-size: 12px; line-height: 4px; color: white; padding-top: 0px; padding-bottom: 12px;">Unit</th>
                                            <th style="border-top: 1px solid black; border-bottom: 1px solid black; border-right: 1px solid black; width: 6rem; background-color: #71717a; text-transform: uppercase; font-size: 12px; line-height: 4px; color: white; padding-top: 0px; padding-bottom: 12px;">Unit Price</th>
                                            <th style="border-top: 1px solid black; border-bottom: 1px solid black; border-right: 1px solid black; width: 6rem; background-color: #71717a; text-transform: uppercase; font-size: 12px; line-height: 4px; color: white; padding-top: 0px; padding-bottom: 12px;">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style="" v-for="material in materialsEstimate" :key="material.id">
                                            <!-- background-color: #71717a; color: white; text-align: left; padding-left: 0.5rem; font-weight: 600; -->
                                            <td style="border-left: 1px solid black; border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: #71717a; color: white; vertical-align: top; text-align: left; padding-left: 0.5rem; font-weight: 600; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;">{{ material.item.toTitleCase('camel') }}</td>
                                            <td style="border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: white; color: black; vertical-align: top; text-align: center; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;">{{ material.type }}</td>
                                            <td style="border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: white; color: black; vertical-align: top; text-align: center; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;">{{ material.quantity }}</td>
                                            <td style="border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: white; color: black; vertical-align: top; text-align: center; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;">{{ material.unit }}</td>
                                            <td style="border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: white; color: black; vertical-align: top; text-align: center; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;">{{ material.unitPrice?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
                                            <td style="border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: white; color: black; vertical-align: top; text-align: center; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;">{{ material.itemCost?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
                                        </tr>
                                        <!-- SubTotal -->
                                        <tr>
                                            <td style="visibility: hidden;"></td>
                                            <td style="visibility: hidden;"></td>
                                            <td style="border: 0; border-right: 1px solid black;"></td>
                                            <td style="border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: white; color: black; vertical-align: top; text-align: center; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;">SubTotal</td>
                                            <td style="border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: white; color: black; vertical-align: top; text-align: center; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;">{{ materialsEstimateSubTotal?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
                                            <td style="visibility: hidden;"></td>
                                        </tr>
                                        <!-- Tax -->
                                        <tr>
                                            <td style="visibility: hidden;"></td>
                                            <td style="visibility: hidden;"></td>
                                            <td style="border: 0; border-right: 1px solid black;"></td>
                                            <td style="border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: white; color: black; vertical-align: top; text-align: center; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;">Tax</td>
                                            <td style="border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: white; color: black; vertical-align: top; text-align: center; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;">{{ materialsEstimateTax?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
                                            <td style="visibility: hidden;"></td>
                                        </tr>
                                        <!-- Delivery -->
                                        <tr>
                                            <td style="visibility: hidden;"></td>
                                            <td style="visibility: hidden;"></td>
                                            <td style="border: 0; border-right: 1px solid black;"></td>
                                            <td style="border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: white; color: black; vertical-align: top; text-align: center; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;">Delivery</td>
                                            <td style="border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: white; color: black; vertical-align: top; text-align: center; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;">{{ materialsDeliveryFee?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
                                            <td style="visibility: hidden;"></td>
                                        </tr>
                                        <!-- Total -->
                                        <tr>
                                            <td style="visibility: hidden;"></td>
                                            <td style="visibility: hidden;"></td>
                                            <td style="border: 0; border-right: 1px solid black;"></td>
                                            <td style="border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: red; color: white; vertical-align: top; text-align: center; font-size: 12px; font-weight: 600; line-height: 4px; padding-top: 0px; padding-bottom: 12px;">Total</td>
                                            <td style="border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: red; color: white; vertical-align: top; text-align: center; font-size: 12px; font-weight: 600; line-height: 4px; padding-top: 0px; padding-bottom: 12px;">{{ materialsEstimateTotal?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
                                            <td style="visibility: hidden;"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        </ProjectDocs>
                    </div>
                    <div class="my-3 w-full">
                        <ProjectDocs :opportunity="opportunity" docTitle="Work Order" :estimate="estimate" ref="workOrder">
                            <div>
                                <div class="w-full flex justify-between mt-8">
                                    <div class="w-1/3 my-8 mr-8 px-2 text-xs flex justify-between align-top bg-gray-200">
                                        <span class="font-semibold">Date:&nbsp;</span>
                                        <span class="text-xs">{{ today }}</span>
                                    </div>
                                    <div class="w-2/3 ml-8">
                                        <div class="flex justify-evenly w-full">
                                            <div class="w-2/4 pr-1">
                                                <div class="text-xs font-bold">Scope of Work</div>
                                                <hr class="border-black mt-2">
                                                <div class="text-xs">{{ estimate.jobDetails.replacementType }}</div>
                                            </div>
                                            <div class="w-2/4 pl-1">
                                                <div class="text-xs font-bold">Preliminary Install date</div>
                                                <hr class="border-black mt-2">
                                                <div class="text-xs">{{ estimate.jobDetails.installDate }}</div>
                                            </div>
                                        </div>
                                        <div class="flex justify-evenly w-full mt-3">
                                            <div class="w-2/4">
                                                <div class="text-xs font-bold">Product Selection</div>
                                                <hr class="border-black mt-2">
                                                <div class="text-xs">{{ estimate.jobDetails.shingleChoice }}</div>
                                            </div>
                                            <div class="w-2/4 pr-1">
                                                <div class="text-xs font-bold">Color</div>
                                                <hr class="border-black mt-2">
                                                <div class="text-xs">{{ estimate.jobDetails.shingleColor }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Additional Details Table -->
                                <table class="mt-4">
                                    <tr>
                                        <td style="border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black; border-right: 1px solid black; width: 13rem; background-color: white; text-transform: uppercase; font-size: 11px; color: black; text-align: center; padding-top: 0px; padding-bottom: 12px; line-height: 4px;">Gutters - Detach/Reset</td>
                                        <td style="border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black; border-right: 1px solid black; width: 5rem; background-color: white; text-transform: uppercase; font-size: 11px; color: black; text-align: center; padding-top: 0px; padding-bottom: 12px; line-height: 4px;">
                                            <span v-if="estimate.structures.gutterReset">Yes</span>
                                            <span v-else>No</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black; border-right: 1px solid black; width: 13rem; background-color: white; text-transform: uppercase; font-size: 11px; color: black; text-align: center; padding-top: 0px; padding-bottom: 12px; line-height: 4px;">Additional Structures</td>
                                        <td style="border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black; border-right: 1px solid black; width: 5rem; background-color: white; text-transform: uppercase; font-size: 11px; color: black; text-align: center; padding-top: 0px; padding-bottom: 12px; line-height: 4px;">
                                            <span v-if="estimate.structures.addStructures">Yes</span>
                                            <span v-else>No</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black; border-right: 1px solid black; background-color: white; text-transform: uppercase; font-size: 11px; color: black; text-align: center; padding-top: 0px; padding-bottom: 12px; line-height: 4px;">Remove Existing Antennas</td>
                                        <td style="border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black; border-right: 1px solid black; background-color: white; text-transform: uppercase; font-size: 11px; color: black; text-align: center; padding-top: 0px; padding-bottom: 12px; line-height: 4px;">
                                            <span v-if="estimate.structures.antennas">Yes</span>
                                            <span v-else>No</span>
                                        </td>
                                    </tr>
                                </table>
                                <!-- Labor Estimate Table -->
                                <table class="mt-4">
                                    <thead>
                                        <tr style="">
                                            <th style="border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black; border-right: 1px solid black; width: 13rem; background-color: #71717a; text-transform: uppercase; font-size: 12px; line-height: 4px; color: white; padding-top: 0px; padding-bottom: 12px;">Item</th>
                                            <th style="border-top: 1px solid black; border-bottom: 1px solid black; border-right: 1px solid black; width: 4rem; background-color: #71717a; text-transform: uppercase; font-size: 12px; line-height: 4px; color: white; padding-top: 0px; padding-bottom: 12px;">Quantity</th>
                                            <th style="border-top: 1px solid black; border-bottom: 1px solid black; border-right: 1px solid black; width: 4rem; background-color: #71717a; text-transform: uppercase; font-size: 12px; line-height: 4px; color: white; padding-top: 0px; padding-bottom: 12px;">Unit</th>
                                            <th style="border-top: 1px solid black; border-bottom: 1px solid black; border-right: 1px solid black; width: 5rem; background-color: #71717a; text-transform: uppercase; font-size: 12px; line-height: 4px; color: white; padding-top: 0px; padding-bottom: 12px;">Unit Price</th>
                                            <th style="border-top: 1px solid black; border-bottom: 1px solid black; border-right: 1px solid black; width: 4rem; background-color: #71717a; text-transform: uppercase; font-size: 12px; line-height: 4px; color: white; padding-top: 0px; padding-bottom: 12px;">Total</th>
                                            <th style="border-top: 1px solid black; border-bottom: 1px solid black; border-right: 1px solid black; width: 14rem; background-color: #71717a; text-transform: uppercase; font-size: 12px; line-height: 4px; color: white; padding-top: 0px; padding-bottom: 12px;">Notes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style="" v-for="item, index in laborEstimate" :key="item.id">
                                            <td style="border-left: 1px solid black; border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: #71717a; color: white; vertical-align: top; text-align: left; padding-left: 0.5rem; font-weight: 600; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;">{{ item.description?.toUpperCase() }}</td>
                                            <td style="border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: white; color: black; vertical-align: top; text-align: center; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;">{{ item.quantity.toFixed(2) }}</td>
                                            <td style="border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: white; color: black; vertical-align: top; text-align: center; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;">{{ item.unit }}</td>
                                            <td style="border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: white; color: black; vertical-align: top; text-align: center; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;">{{ item?.unitPrice?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
                                            <td style="border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: white; color: black; vertical-align: top; text-align: center; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;">{{ item?.laborCost?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
                                            <td style="border-top: 0; border-right: 1px solid black; background-color: white; color: black; vertical-align: top; text-align: center; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;"></td>
                                        </tr>
                                        <tr style="">
                                            <td style="border-left: 1px solid black; border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: #71717a; color: white; vertical-align: top; text-align: left; padding-left: 0.5rem; font-weight: 600; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;">MISCELLANEOUS (ADD NOTE)</td>
                                            <td style="border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: white; color: black; vertical-align: top; text-align: center; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;"></td>
                                            <td style="border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: white; color: black; vertical-align: top; text-align: center; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;">EACH</td>
                                            <td style="border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: white; color: black; vertical-align: top; text-align: center; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;"></td>
                                            <td style="border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: white; color: black; vertical-align: top; text-align: center; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;">{{ (0).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
                                            <td style="border-bottom: 1px solid black; border-top: 0; border-right: 1px solid black; background-color: white; color: black; vertical-align: top; text-align: center; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;"></td>
                                        </tr>
                                        <tr>
                                            <td style="visibility: hidden;"></td>
                                            <td style="visibility: hidden;"></td>
                                            <td style="border: 0; border-right: 1px solid black;"></td>
                                            <td style="border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: #71717a; color: white; vertical-align: top; text-align: center; font-size: 10px; font-weight: bold; line-height: 4px; padding-top: 0px; padding-bottom: 12px;">Total</td>
                                            <td style="border-top: 0; border-bottom: 1px solid black; border-right: 1px solid black; background-color: white; color: black; vertical-align: top; text-align: center; font-size: 10px; line-height: 4px; padding-top: 0px; padding-bottom: 12px;">{{ laborEstimateTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ProjectDocs>
                    </div>
                    <div class="my-3 w-full">
                        <ProjectDocs :opportunity="opportunity" docTitle="Commission Sheet" :estimate="estimate" ref="commissionSheet">
                            <div>
                            <div class="flex justify-center mt-10 mx-4 w-full">
                                <div class="mr-10 w-1/2">
                                    <div class="flex justify-between bg-gray-200 mb-1 pb-5 h-8 text-sm mx-2 px-2">
                                        <span class="font-bold">Date</span>
                                        <span class="text-right">{{ today }}</span>
                                    </div>
                                    <div class="flex justify-between mb-1 text-sm mx-2 px-2">
                                        <span class="font-bold">Employee Type</span>
                                        <span class="text-right">{{ estimate.author?.roles?.[0]?.toTitleCase('camel') }}</span>
                                    </div>
                                    <div class="flex justify-between mb-1 text-sm mx-2 px-2">
                                        <span class="font-bold">Self or Lead</span>
                                        <span class="text-right">{{ estimate.jobDetails.leadType }}</span>
                                    </div>
                                    <div v-for="item in commissionSheet">
                                        <div class="flex justify-between mb-1 text-sm mx-2 px-2" v-if="item.display" :class="item.label.includes('Rep') ? 'mt-4 pt-4 border-t' : ''">
                                            <span class="font-bold">{{ item.label }}</span>
                                            <span v-if="item.label.includes('%')" class="text-right">{{ (item.value*100).toFixed(0) }}%</span>
                                            <span v-else class="text-right">{{ item.value?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="ml-10 w-1/2">
                                    <div class="flex justify-between bg-gray-200 mb-0 pb-5 h-8 text-sm mx-2 px-2">
                                        <span class="font-bold">Commission Triggers</span>
                                    </div>
                                    <div class="flex justify-between pt-0 mt-0 pb-3 text-sm mx-2 px-2 bg-zinc-300" v-for="value, key in estimate.commissionTriggers">
                                        <span class="font-bold mr-10">{{ key.toTitleCase('camel') }}</span>
                                        <span class="text-left">{{ value?.toTitleCase?.('camel') || value || 'No' }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </ProjectDocs>
                    </div>
                    <div class="my-3 w-full">
                        <ProjectDocs :opportunity="opportunity" docTitle="Property Details" :estimate="estimate" header="bw" ref="propertyDetails">
                            <div class="mt-8 space-y-1">
                                <div class="flex space-x-4">
                                    <div class="w-20 text-2xs">Customer:</div>
                                    <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.jobDetails.customerName }}</div>
                                </div>
                                <div class="flex space-x-4">
                                    <div class="w-20 text-2xs">Address:</div>
                                    <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.jobDetails.street }}</div>
                                </div>
                                <div class="grid grid-rows-7 grid-flow-col gap-y-1 gap-x-4">

                                    <!-- <div class="space-y-1"> -->
                                        <div class="flex space-x-4">
                                            <div class="w-36 text-2xs">Pitch of Roof:</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.dimensions.roofPitch }}</div>
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class="w-36 text-2xs">Number of Layers:</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.details.extraLayers+1 }}</div>
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class="w-36 text-2xs">Ridge Length:</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.dimensions.ridgesLF }}</div>
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class="w-36 text-2xs">Existing Ventilation:</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.structures.existingVent }}</div>
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class="w-36 text-2xs">A/C in Attic?</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.structures.atticAC }}</div>
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class="w-36 text-2xs">Existing Roof Material:</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.jobDetails.existingRoofMaterial }}</div>
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class="w-36 text-2xs">Existing Skylights?</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.structures.numSkyLights || 'No' }}</div>
                                        </div>
                                    <!-- </div> -->

                                    <!-- <div class="space-y-1"> -->
                                        <div class="flex space-x-4">
                                            <div class="w-36 text-2xs">Any areas 3/12 or below?</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.dimensions.baseCapSheet ? 'Yes' : 'No' }}</div>
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class="w-36 text-2xs">Height (Stories of Roof):</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.dimensions.valleysLF }}</div>
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class="w-36 text-2xs">Hip Length:</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.dimensions.hipsLF }}</div>
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class="w-36 text-2xs">No. of Chimneys:</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.structures.numChimneys }}</div>
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class="w-36 text-2xs">Exposed Soffit?</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2"></div>
                                        </div>
                                        <div class="flex space-x-4 col-span-2">
                                            <div class="w-36 text-2xs">Existing Pipe Boots: </div>
                                            <div class="flex-grow flex">
                                                <div class="text-2xs">2”</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2 text-center"/>
                                                <div class="text-2xs">3”</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2 text-center">{{ estimate.materials.pipeBoots }}</div>
                                                <div class="text-2xs">4”</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2 text-center">{{ estimate.materials.leadPipeBoots }}</div>
                                            </div>
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class="w-36 text-2xs">Qty:</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.structures.numSkyLights || 'No' }}</div>
                                        </div>
                                    <!-- </div> -->

                                    <!-- <div class="space-y-1"> -->

                                        <div class="flex space-x-4">
                                            <div class="w-36 text-2xs">Existing Flat Areas?</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.dimensions.baseCapSheet ? 'Yes' : 'No' }}</div>
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class="w-36 text-2xs">Total Perimeter:</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.dimensions.perimeterLF }}</div>
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class="w-36 text-2xs">Valley Length:</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.dimensions.valleysLF }}</div>
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class="w-36 text-2xs">Exist. Chimney Material:</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2"></div>
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class="w-36 text-2xs">Existing Drip Edge?</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.structures.existingDripEdge ? 'Yes' : 'No' }}</div>
                                        </div>
                                    <!-- </div> -->

                                </div>

                                <div>
                                    <div class="font-semibold my-2 text-center">Installation Details</div>
                                    <div class="grid grid-cols-3 gap-4">

                                        <div class="space-y-1">
                                            <div class="flex space-x-4">
                                                <div class="w-36 text-2xs">Square Footage of Roof:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.dimensions.totalRoofArea }}</div>
                                            </div>
                                            <div class="flex space-x-4">
                                                <div class="w-36 text-2xs">Manufacturer:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2"></div>
                                            </div>
                                            <div class="flex space-x-4">
                                                <div class="w-36 text-2xs">Install Rolled Roofing</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.dimensions.baseCapSheet ? 'Yes' : 'No' }}</div>
                                            </div>
                                            <div class="flex space-x-4">
                                                <div class="w-36 text-2xs">Install New Drip Edge:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ materialsEstimate.find(e => e.item == 'dripEdge')?.quantity ? 'Yes' : 'No' }}</div>
                                            </div>
                                        </div>

                                        <div class="space-y-1">
                                            <div class="flex space-x-4">
                                                <div class="w-36 text-2xs">Squares to Tear Off:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ ((estimate.dimensions.totalRoofArea||0)/100)?.toFixed(2) }}</div>
                                            </div>
                                            <div class="flex space-x-4">
                                                <div class="w-36 text-2xs">Style:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.jobDetails.shingleChoice.toTitleCase('kebab') }}</div>
                                            </div>
                                            <div class="flex space-x-4">
                                                <div class="w-36 text-2xs">Color:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.jobDetails.shingleColor }}</div>
                                            </div>
                                            <div class="flex space-x-4">
                                                <div class="w-36 text-2xs">Color:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ materialsEstimate.find(e => e.item == 'dripEdge')?.type }}</div>
                                            </div>
                                        </div>

                                        <div class="space-y-1">
                                            <div class="flex space-x-4">
                                                <div class="w-36 text-2xs">Squares to Install:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ totalSqOrdered?.toFixed(2) }}</div>
                                            </div>
                                            <div class="flex space-x-4">
                                                <div class="w-36 text-2xs">Color:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.jobDetails.shingleColor }}</div>
                                            </div>
                                            <div class="flex space-x-4">
                                                <div class="w-36 text-2xs">Squares to Install:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.dimensions.baseCapSheet }}</div>
                                            </div>
                                            <div class="flex space-x-4">
                                                <div class="w-36 text-2xs">Amount (In Feet):</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.dimensions.perimeterLF }}</div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="font-semibold my-2 text-sm">New Ventilation</div>
                                    <div class="">

                                        <div class="space-y-1">
                                            <div class="flex space-x-4">
                                                <div class="w-36 text-2xs">Box Vents:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.materials.boxVent750 ? 'Yes' : 'No' }}</div>
                                                <div class=" text-2xs">Qty:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.materials.boxVent750 }}</div>
                                                <div class=" text-2xs">Color:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ materialsEstimate.find(e => e.item == 'boxVent')?.type }}</div>
                                                <div class=" text-2xs">Existing Satellite?</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2"></div>
                                            </div>
                                            <div class="flex space-x-4">
                                                <div class="w-36 text-2xs">Turbines:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2"></div>
                                                <div class=" text-2xs">Qty:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2"></div>
                                                <div class=" text-2xs">Color:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2"></div>
                                                <div class=" text-2xs">Dispose or Reset?:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2"></div>
                                            </div>
                                            <div class="flex space-x-4">
                                                <div class="w-36 text-2xs">Ridge Vents:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.dimensions.ridgeVentLF ? 'Yes' : 'No' }}</div>
                                                <div class=" text-2xs">LF:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.dimensions.ridgeVentLF }}</div>
                                                <div class=" text-2xs">Location:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2"></div>
                                            </div>
                                            <div class="flex space-x-4">
                                                <div class="w-36 text-2xs">New Pipe Boots:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2 text-center">Neo/Lead</div>
                                                <div class="text-2xs">2”</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2 text-center"/>
                                                <div class="text-2xs">3”</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2 text-center">{{ estimate.materials.pipeBoots }}</div>
                                                <div class="text-2xs">4”</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2 text-center">{{ estimate.materials.leadPipeBoots }}</div>
                                                <div class="text-2xs">Split Boot</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2 text-center">{{ estimate.materials.electricSplitBoot }}</div>
                                            </div>
                                            <div class="flex space-x-4">
                                                <div class="w-36 text-2xs">Install Skylight/Flashing:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.laborItems.skylightFlashingReFlash ? 'Yes' : 'No' }}</div>
                                                <div class=" text-2xs">Qty:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.laborItems.skylightFlashingReFlash }}</div>
                                                <div class=" text-2xs">(Quotes/Waiver Must Be Provided)</div>
                                            </div>
                                            <div class="flex space-x-4">
                                                <div class="w-36 text-2xs">Are we installing flashing?</div>
                                                <div class=" text-2xs">Step LF:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.dimensions.stepFlash }}</div>
                                                <div class=" text-2xs">Counter LF:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.laborItems.counterFlashing }}</div>
                                                <div class=" text-2xs">Wall LF:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.dimensions.wallFlash }}</div>
                                                <div class=" text-2xs">Chimney Qty:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2">{{ estimate.structures.numChimneys }}</div>
                                            </div>
                                            <div class="flex space-x-4">
                                                <div class="w-36 text-2xs">Special Notes/Requests:</div>
                                                <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2" />
                                            </div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2 h-4 pt-4" />
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2 h-4" />
                                            <!-- <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2 h-4" /> -->
                                        </div>

                                    </div>
                                </div>

                                <div class="flex border divide-x border-black divide-black !mt-4">
                                    <div class="w-1/2 aspect-video p-2 space-y-1">
                                        <div class="uppercase font-bold text-center text-sm -mt-2">Gutters</div>
                                        <div class="flex space-x-4">
                                            <div class=" text-2xs">Gutter Size:</div>
                                            <div class=" text-2xs">5”</div>
                                            <div class=" text-2xs">6”</div>
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class=" text-2xs">Gutter Color (per Sample):</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2" />
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class=" text-2xs">Quantity (in LF):</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2" />
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class=" text-2xs">Number of Downspouts:</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2" />
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class=" text-2xs">Quantity (in LF):</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2" />
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class=" text-2xs">Are we installing gutters on the entire house?</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2" />
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class=" text-2xs">If no, in detail, explain where we are installing gutters/downspouts:</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2" />
                                        </div>
                                        <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2 h-4 pt-4" />
                                        <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2 h-4" />
                                        <div class="flex space-x-4">
                                            <div class=" text-2xs">Other Ext. Work Details:</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2" />
                                        </div>
                                        <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2 h-4 pt-4" />
                                        <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2 h-4" />
                                        <!-- <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2 h-4" /> -->
                                        <!-- <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2 h-4" /> -->

                                    </div>
                                    <div class="w-1/2 aspect-video p-2 space-y-1">
                                        <div class="uppercase font-bold text-center text-sm -mt-2">Exterior</div>
                                        <div class="uppercase underline font-bold text-xs">Shutters</div>
                                        <div class="flex space-x-4">
                                            <div class=" text-2xs">Size:</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2" />
                                            <div class=" text-2xs">Color:</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2" />
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class=" text-2xs">Quantity:</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2" />
                                        </div>
                                        <div class="uppercase font-bold text-center text-xs pt-4">Include a Photo</div>
                                        <div class="uppercase underline font-bold text-xs">Siding</div>
                                        <div class="flex space-x-4">
                                            <div class=" text-2xs">Siding Type:</div>
                                            <div class=" text-2xs">Dutchlap</div>
                                            <div class=" text-2xs">Straight</div>
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class=" text-2xs">Siding Size:</div>
                                            <div class=" text-2xs">4”</div>
                                            <div class=" text-2xs">4.5”</div>
                                            <div class=" text-2xs">Other</div>
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class=" text-2xs">Siding Color:</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2" />
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class=" text-2xs">Siding Style:</div>
                                            <div class=" text-2xs">Smooth</div>
                                            <div class=" text-2xs">Textured</div>
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class=" text-2xs">Are we installing on the whole house?:</div>
                                            <div class=" text-2xs">Y</div>
                                            <div class=" text-2xs">N</div>
                                        </div>
                                        <div class="flex space-x-4">
                                            <div class=" text-2xs">If no, describe where:</div>
                                            <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2" />
                                        </div>
                                        <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2 h-4 pt-4" />
                                        <div class="uppercase text-center font-bold text-xs">Need a Photo or piece of existing siding</div>
                                    </div>
                                </div>

                                <div>
                                    <div class="text-center text-xs font-bold italic">NOTE THAT YOU ARE SOLELY RESPONSIBLE FOR THE ACCURACY OF THIS PAGE, PUTTING THE WRONG INFORMATION CAN/WILL LEAD TO A BACK CHARGE SO ENSRRE ALL INFORMATION IS ACCURATE AND MATCHES THE CONTRACT</div>
                                    <div class="flex space-x-4 mt-4">
                                        <div class=" text-xs">Employee Signature:</div>
                                        <div class="flex-grow text-2xs border-b border-black pb-2 -mb-2" />
                                    </div>
                                </div>

                            </div>
                        </ProjectDocs>
                    </div>

                    <button @click="saveToMango({ uploadPDFs: true })" class="mx-auto flex px-3 py-2 rounded border bg-blue-500 text-white font-semibold mt-8">Publish to JobNimbus</button>

                </div>
            </div>
        </DetailDrawer>

        <button @click="saveToMango" class="mx-auto flex px-3 py-2 rounded border bg-red-500 text-white font-semibold">Save Estimate</button>
        <!-- <button @click="applyTestData" class="px-3 py-2 rounded border bg-red-500 text-white font-semibold fixed top-0 right-0 m-4">Dummy Data</button> -->

        <div v-if="loading" class="fixed inset-0 dark:bg-black/50 bg-white/70 flex items-center justify-center w-full h-screen backdrop-blur">
            <div class="text-lg text-2xl font-semibold">{{ loadingMessage }}</div>
        </div>

    </div>

</template>

<script>
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import Mango from '../../helpers/mango.js'

import { commissionTriggers, commissionItems } from '../../helpers/commission.js'
import { worksheets, worksheetResolvers } from '../../helpers/worksheets.js'
import { materials, materialItems, materialResolvers } from '../../../../config/helpers/materials.js'
import { tasks } from '../../../../config/helpers/tasks.js'
import inputs from '../../helpers/inputs.js'

import ProjectDocs from '../partials/projectDocs.vue'
import DetailDrawer from '../layout/detailDrawer.vue'

import testEstimate from '../../helpers/testEstimate'

let objectify = (acc, detail) => {
    acc[detail.id] = detail.value;
    return acc;
}

export default {
    components: { ProjectDocs, DetailDrawer },
    data() {
        return {

            suppliers: [],
            subs: [],

            inputs,
            tasks,

            materials,
            materialItems,
            materialResolvers,
            materialsDeliveryFee: 150,
            materialsTax: .10,

            worksheets,
            worksheetResolvers,

            commissionItems,

            commissionTriggers,

            showCustomerAmount: false,

            opportunity: this.$route.params?.id || null,
            id: null,

            loading: false,
            loadingMessage: null,

            estimate: testEstimate ? testEstimate : {
                title: '',
                opportunity: '',
                branch: '',
                jobDetails: {
                    customerName: '',
                    email: '',
                    phone: '',
                    street: '',
                    city: '',
                    state: '',
                    zip: '',
                    existingRoofMaterial: '',
                    supplier: '',
                    shingleChoice: '',
                    shingleColor: '',
                    sub: '',
                    replacementType: '',
                    leadType: '',
                    materialDeliveryDate: dayjs().format('YYYY-MM-DD'),
                    tentativeInstallDate: dayjs().format('YYYY-MM-DD'),
                },
                dimensions: {
                    totalRoofArea: 0,
                    facets7_12: 0,
                    facets8_12: 0,
                    facets9_12: 0,
                    facets10_12: 0,
                    facets11_12: 0,
                    facets12_12: 0,
                    noAccessSQ: 0,
                    ridgesLF: 0,
                    hipsLF: 0,
                    valleysLF: 0,
                    eavesLF: 0,
                    rakesLF: 0,
                    ridgeVentLF: 0,
                    eaveExp: 0,
                    perimeterLF: 0,
                    squares: 0,
                    stepFlash: 0,
                    wallFlash: 0,
                    xlDripEdgeArea: 0,
                    baseCapSheet: 0,
                    roofPitch: '',
                    secondStorySF: 0,
                },
                structures: {
                    gutters: false,
                    existingDripEdge: false,
                    numChimneys: 0,
                    numSkyLights: 0,
                    newVent: false,
                    gutterReset: false,
                    antennas: false,
                    addStructures: false,
                    powerVentCover: 0,
                    crickets: 0,
                    mansard: 0,
                    existingVent: '',
                    broanVents: 0,
                    atticAC: false,
                    replaceSkylight: 0,
                    flashSkylight: 0,
                },
                materials: {
                    boxVent750: 0,
                    pipeBoots: 0,
                    leadPipeBoot: 0,
                    electricSplitBoot: 0,
                    ccBV: 0,
                    supplyCaulk: false,
                    sprayPaint: false,
                    decking: 0,
                },
                materialItems: {
                    hipAndRidgeCap: 0,
                    starter: 0,
                    iceAndWater: 0,
                    underlayment: 0,
                    dripEdge: 0,
                    ridgeVent: 0,
                    boxVent: 0,
                    powerAssistedVent: 0,
                    pipeBoots: 0,
                    electricSplitBoot: 0,
                    leadPipeBoot: 0,
                    wallFlashing: 0,
                    stepFlashing: 0,
                    counterFlashing: 0,
                    coilNails: 0,
                    buttonCapNails: 0,
                    decking: 0,
                    caulking: 0,
                    capSheet: 0,
                    baseSheet: 0,
                    xlDripEdge: 0,
                    broanVent: 0,
                },
                laborItems: {
                    sqToBeInstalled: 0,
                    sqToBeRemoved: 0,
                    extraLayers: 0,
                    steepFacetsSq712: 0,
                    steepFacetsSq812: 0,
                    steepFacetsSq912: 0,
                    steepFacetsSq1012: 0,
                    steepFacetsSq1112: 0,
                    steepFacetsSq1212: 0,
                    decking: 0,
                    dumpFeesHaulOff: 0,
                    tripCharge: 0,
                    guttersRemoved: 0,
                    noAccessPerSq: 0,
                    installCricketsEach: 0,
                    cutInCoverUpBoxVentsEach: 0,
                    replaceSkylightFlashingInc: 0,
                    skylightFlashingReFlash: 0,
                    counterFlashing: 0,
                    decking1xBoardsLft: 0,
                    extraLayerFeltTearOff: 0,
                    mansard: 0,
                    stepFlashing: 0,
                    secondStoryPerSq: 0,
                    cutInRidgeVent: 0,
                    chimneyFlashing: 0,
                },
                details: {
                    extraLayers: 0,
                    chargedTrips: 0,
                    extFeltLayers: 0,
                    waste: 0,
                    replaceDecking: 0,
                    orderNewDripEdge: false,
                    includeIceAndWaterOnEaves: false,
                },
                cashEstimate: {
                    cashEstimate: false,
                    subTotal: 0,
                    costPerSq: 0,
                    warrantySold: 0,
                    desiredProfitMargin: 0,
                    pricePerSq: 0,
                    totalPrice: 0,
                    profit: 0,
                    marginBeforeCommission: 0,
                    commission: 0,
                    marginAfterCommission: 0,
                    totalProjectCost: 0,
                },
                insuranceWork: {
                    acvCheck: 0,
                    recoverableDepreciationAndTaxes: 0,
                    deductible: 0,
                    totalInsuranceRCV: 0,
                    roofRCV: 0,
                    workNotDoing: 0,
                    supplements: 0,
                    oAndP: 0,
                    warrantySold: 0,
                    addlOutOfPocketExpenses: 0,
                    profit: 0,
                    marginBeforeCommission: 0,
                    commission: 0,
                    marginAfterCommission: 0,
                    totalProjectCost: 0,
                },
                outOfScope: {
                    line1: 0,
                    line2: 0,
                    line3: 0,
                    line4: 0,
                    line5: 0,
                    line6: 0,
                    line7: 0,
                    line8: 0,
                    line9: 0,
                    line10: 0,
                    oosTotal: 0,
                },
                overhead: {
                    line1: 0,
                    line2: 0,
                    line3: 0,
                    line4: 0,
                    line5: 0,
                    line6: 0,
                    line7: 0,
                    line8: 0,
                    line9: 0,
                    line10: 0,
                    oAndPTotal: 0,
                    oosOAndPTotal: 0,
                },
                commissionTriggers: {
                    freeUpgradeDrop: false,
                    drpProgram: false,
                    drpAmount: 0,
                    secondEmployeeType: '',
                },
                commissionItems: {
                    deductible: 0,
                    totalProjectCost: 0,
                    commissionPercentage: 0,
                    B19: true,
                    B20: false,
                    B21: 0,
                    C19: 0,
                    C20: 0,
                    C21: 0,
                    commissionNet: 0,
                    C23: 0,
                    C24: 0,
                    deductibleSplit: 0,
                    oAndPSplit: 0,
                    warrantySold: 0,
                    warrantyCommission: 0,
                    drpCreditAmount: 0,
                    repTotalPay: 0,
                },
                customerPaymentSheet: null,
                customerQuote: null,
                materialOrder: null,
                workOrder: null,
                commissionSheet: null,
                propertyDetails: null,
            },

        }
    },
    async mounted() {

        this.subs = await Mango.subs({ limit: 999, search: {branches: '6542bfb361c2b192db9dd9ae' } })
        this.inputs.sub.options = this.subs.map(s => ({ label: s.title, value: s.id }))

        this.suppliers = await Mango.suppliers({ limit: 999, search: {branches: '6542bfb361c2b192db9dd9ae' } })
        this.inputs.supplier.options = this.suppliers.map(s => ({ label: s.title, value: s.id }))

        if (this.$route.params?.id) this.opportunity = await Mango.opportunity(this.$route.params?.id, {depthLimit: 1})
        this.setOpportunity(this.opportunity)

    },
    methods: {
        applyTestData() {
            this.estimate = testEstimate
        },
        async setOpportunity(op) {

            this.estimate.opportunity = op
            this.estimate.author = op.author
            console.log('op.shotgun?.[0]?.roles?.[0]', op.shotgun?.[0]?.roles?.[0])
            this.estimate.commissionTriggers.secondEmployeeType = op.shotgun?.[0]?.roles?.[0] || ''
            this.estimate.title = `${op.ownerInfo?.lastName} - ${op.address?.address}`

            let estimate = (await Mango.estimates({search: {opportunity: op.id}, depthLimit: 1}))?.[0]
            if (estimate) {
                estimate.jobDetails.supplier = estimate.jobDetails.supplier?.id || estimate.jobDetails.supplier
                estimate.jobDetails.sub = estimate.jobDetails.sub?.id || estimate.jobDetails.sub
                return this.estimate = estimate
            }

            let detailsMap = {
                customerName: op.ownerInfo?.firstName ? `${op.ownerInfo.firstName} ${op.ownerInfo?.lastName || ''}` : '',
                email: op.ownerInfo?.emailAddress,
                phone: op.ownerInfo?.phoneNumber,
                street: op.address?.address,
                city: op.address?.city,
                state: op.address?.state,
                zip: op.address?.zip,
            }
            this.estimate.jobDetails = {...detailsMap, ...this.estimate.jobDetails}

            // Select the first available option if none is selected
            Object.keys(this.estimate.jobDetails).forEach(detail => {
                let options = this.inputs[detail]?.options
                let value = this.estimate.jobDetails[detail]
                if (options && !value) this.estimate.jobDetails[detail] = options[0]?.value || options[0];
            });

        },
        async saveToMango({ uploadPDFs } = {}) {
            this.loading = true
            let estimate = JSON.parse(JSON.stringify(this.estimate))
            let documents = [
                'customerPaymentSheet',
                'customerQuote',
                'materialOrder',
                'workOrder',
                'commissionSheet',
                'propertyDetails',
            ]
            if (uploadPDFs) {
                for (let doc of documents) {
                    if (this.$refs[doc]) {
                        this.loadingMessage = `Generating ${doc.toTitleCase('camel')}...`
                        let pdf = await this.$refs[doc].exportPDF()
                        this.loadingMessage = `Uploading ${doc.toTitleCase('camel')}...`
                        let url = await Mango.upload(pdf)
                        estimate[doc] = url
                    }
                }
            } else {
                for (let doc of documents) {
                    delete estimate[doc]
                }
            }
            this.loadingMessage = 'Saving Estimate...'
            let response = await Mango.estimates.save(estimate)
            if (response.id) this.estimate.id = response.id
            this.loading = false
        },
        updateShingleOptions() {
            console.log('updating shingleChoice')

            // Update the shingle options and color options
            this.inputs.shingleChoice.options = this.availableMaterials.filter(m => m.itemId === 'shingles').map(s => ({ label: s.type, value: s.id }));
            let selectedShingles = this.materials[this.estimate.jobDetails.shingleChoice]
            this.inputs.shingleColor.options = selectedShingles?.colors?.map(c => c.label) || [];

            let selectedShinglesValid = !!this.inputs.shingleChoice.options.find(s => s.value === this.estimate.jobDetails.shingleChoice);
            let selectedColorValid = !!this.inputs.shingleColor.options.find(c => c === this.estimate.jobDetails.shingleColor);

            console.log('valid', selectedShinglesValid, selectedColorValid)

            // Validate shingles with supplier
            if (!selectedShinglesValid) {
                if (this.selectedShingles) {
                    Swal.fire({
                        title: 'Invalid Shingle Choice',
                        text: `${this.selectedSupplier.title} does not carry ${this.materials[this.selectedShingles].type} shingles. Please select a valid option.`,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.estimate.jobDetails.shingleChoice = null;
            }

            // Validate color with shingles
            if (!selectedColorValid) {
                if (this.selectedColor && selectedShinglesValid) {
                    Swal.fire({
                        title: 'Invalid Shingle Color',
                        text: `${this.materials[this.selectedShingles].type} shingles do not come in ${this.selectedColor}. Please select a valid option.`,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.estimate.jobDetails.shingleColor = null;
            }

        }
    },
    computed: {
        today() {
            return dayjs().format(`M/D/YYYY`);
        },
        colors() {
            this.materials
        },
        selectedSupplier() {
            console.log('this.estimate.jobDetails.supplier', this.estimate.jobDetails.supplier)
            if (this.estimate.jobDetails.supplier?.id) return this.estimate.jobDetails.supplier
            return this.suppliers.find(s => s.id == this.estimate.jobDetails.supplier)
        },
        selectedSub() {
            return this.subs.find(s => s.id == this.estimate.jobDetails.sub)
        },
        selectedShingles() {
            return this.estimate.jobDetails.shingleChoice
        },
        selectedColor() {
            return this.estimate.jobDetails.shingleColor
        },
        availableMaterials() {
            let availableMaterials = []
            let availableIds = this.selectedSupplier?.materials?.filter(m => m.cost)?.map(m => m.id) || []
            for (let id in materials) if (availableIds.includes(id)) availableMaterials.push({...materials[id], id})
            return availableMaterials
            // return this.selectedSupplier?.materials?.filter(m => m.cost)?.map(m => m.id);
        },
        availableTasks() {
            return this.selectedSub?.tasks?.filter(t => t.cost)?.map(t => t.id);
        },
        perimeterLF() {
            return this.estimate.dimensions.eavesLF + this.estimate.dimensions.rakesLF;
        },
        squares() {
            return (this.estimate.dimensions.totalRoofArea||0) / 100;
        },
        totalSqOrdered() {
            let shingles = this.materialsEstimate.find(e => e.item == 'shingles')?.quantity || 0;
            let hipAndRidgeCap = this.materialsEstimate.find(e => e.item == 'hipAndRidgeCap')?.quantity || 0;
            let starter = this.materialsEstimate.find(e => e.item == 'starter')?.quantity || 0;
            return (shingles + hipAndRidgeCap + starter)/3;
        },
        materialsEstimate() {
            if (!this.suppliers?.length) return []

            // let things = ['shingles', ...materialItems]
            return materialItems.map(name => {

                let resolver = this.materialResolvers[name]
                let value = name === 'shingles' ? this.estimate.jobDetails.shingleChoice : this.estimate.materialItems[name]
                let itemModel = this.materials[value]

                let unitPrice = value ? this.selectedSupplier.materials.find(m => m.id == value)?.cost : 0;
                let quantity = itemModel?.quantity ? resolver(this.estimate, itemModel.quantity) || 0 : 0;

                let estimate = {
                    item: name,
                    unit: itemModel?.unit,
                    unitQty: itemModel?.quantity,
                    type: value?.toTitleCase?.('kebab') || value,
                    id: value,
                    quantity,
                    unitPrice,
                    itemCost: itemModel?.quantity ? (quantity * unitPrice) : 0,
                }

                if (name === 'shingles') estimate.shingleColor = this.estimate.jobDetails.selectedColor

                return estimate

            })
        },
        laborEstimate() {
            if (!this.selectedSub) return []
            return Object.keys(this.estimate.laborItems).map(key => {

                let itemModel = tasks[key];
                let item = this.estimate.laborItems[key]

                const id = key;
                const unit = itemModel?.unit;
                const unitQty = itemModel?.quantity;
                const description = itemModel?.description;
                const unitPrice = this.selectedSub?.tasks?.find(t => t.id == key)?.cost;

                let quantity = itemModel.resolver(this.estimate, this.materialsEstimate);
                let laborCost = quantity * unitPrice;

                return { unit, id, unitQty, description, unitPrice, quantity, laborCost };

            })
        },
        materialsEstimateSubTotal() {
            // return the totalCost of imtemCost for all materials and round up to two decimal places
            return this.materialsEstimate.reduce((acc, item) => acc + (item?.itemCost || 0), 0);
        },
        materialsEstimateTax() {
            return this.materialsEstimateSubTotal * (this?.materialsTax || 0);
        },
        materialsEstimateTotal() {
            return this.materialsEstimateSubTotal + this.materialsEstimateTax + (this?.materialsDeliveryFee || 0);
        },
        laborEstimateTotal() {
            return this.laborEstimate.reduce((acc, item) => acc + (item?.laborCost || 0), 0);
        },
        jobTotal() {
            return this.materialsEstimateTotal + this.laborEstimateTotal;
        },
        customerTotal() {
            return this.jobTotal * (1+this.estimate.cashEstimate.desiredProfitMargin);
        },
        worksheetEstimates() {
            console.log('running')
            let values = {};
            let worksheets = ['cashEstimate', 'insuranceWork', 'outOfScope', 'overhead'];
            for (let sheet of worksheets) {
                if (!values[sheet]) values[sheet] = {};
                let resolvers = this.worksheetResolvers.find(r => r.id === sheet).resolvers;
                for (let key in this.estimate[sheet]) {
                    let worksheet = resolvers.find(r => r.id === key);
                    let value = !worksheet?.resolver ? this.estimate[sheet][key] : worksheet.resolver({
                        estimate: this.estimate,
                        squares: this.squares,
                        worksheets: this.worksheets,
                        laborEstimateTotal: this.laborEstimateTotal,
                        materialsEstimateTotal: this.materialsEstimateTotal,
                    });
                    this.estimate[sheet][key] = value;
                    values[sheet][key] = value;
                }
            }
            return values;
        },
        commissionSheet() {
            return this.commissionItems.map(item => {
                const id = item.id;
                const label = item.label;
                let value = item.resolver(this.estimate);
                const display = item.display;
                const type = item.type;

                return { id, label, value, display, type };
            })
        },
        customerPaymentSheet() {
            let totalProjectCost = this.estimate.insuranceWork.acvCheck + this.estimate.insuranceWork.addlOutOfPocketExpenses - this.estimate.insuranceWork.workNotDoing
            let initialBalanceDue = totalProjectCost - this.estimate.insuranceWork.acvCheck
            let outOfPocket = this.estimate.insuranceWork.addlOutOfPocketExpenses + this.estimate.insuranceWork.deductible

            return {
                deductible: this.estimate.insuranceWork.deductible,
                totalClaimAmount: this.estimate.insuranceWork.acvCheck,
                workNotDoing: this.estimate.insuranceWork.workNotDoing*-1,
                additionalExpenses: this.estimate.insuranceWork.addlOutOfPocketExpenses,
                totalProjectCost,
                acvCheck: this.estimate.insuranceWork.acvCheck,
                initialBalanceDue,
                outOfPocket,
                balanceDue: initialBalanceDue - outOfPocket,
                depreciation: this.estimate.insuranceWork.recoverableDepreciationAndTaxes,
            }
        },
        customerQuote() {
            if (!this.materialsEstimate?.length || !this.laborEstimate?.length) return
            return {
                installShingles: {
                    qty: this.laborEstimate.find(i => i.id == 'sqToBeInstalled')?.quantity?.toFixed(2),
                    unit: 'SQ',
                    amount: this.materialsEstimate.find(i => i.item == 'shingles')?.itemCost,
                    description: 'Remove existing and install new shingles along with starter strip and ridge cap (Including required waste)'
                },
                venting: {
                    qty: this.estimate.materials.boxVent750 == 0 ? this.materialsEstimate.find(i => i.item=='ridgeVent')?.quantity : this.materialsEstimate.find(i => i.item=='boxVent')?.quantity,
                    unit: 'EA',
                    amount: this.estimate.materials.boxVent750 == 0 ? this.materialsEstimate.find(i => i.item=='boxVent')?.itemCost + this.materialsEstimate.find(i => i.item=='ridgeVent')?.itemCost : 0,
                    description: this.estimate.materials.boxVent750 > 0 ? 'Remove existing and install new box vents' : 'Remove existing box vents and install new ridge vents'
                },
                hipAndRidge: {
                    qty: this.estimate.dimensions.hipsLF,
                    unit: 'LF',
                    amount: this.materialsEstimate.find(i => i.item=='hipAndRidgeCap')?.itemCost,
                    description: 'Install new ridge cap at all hips and ridges'
                },
                iceAndWater: {
                    qty: this.materialsEstimate.find(i => i.item=='iceAndWater')?.quantity,
                    unit: 'BD',
                    amount: this.materialsEstimate.find(i => i.item=='iceAndWater')?.itemCost,
                    description: 'Install Ice & Water shield to code at all eave edges, eave exposures, valleys, transistions,and penetrations, to code'
                },
                underlayment: {
                    qty: this.materialsEstimate.find(i => i.item=='underlayment')?.quantity,
                    unit: 'BD',
                    amount: this.materialsEstimate.find(i => i.item=='underlayment')?.itemCost,
                    description: 'Install new synthetic underlayment (included upgrade free of charge)'
                },
                dripEdge: {
                    qty: this.estimate.dimensions.perimeterLF?.toFixed(2),
                    unit: 'LF',
                    amount: this.materialsEstimate.find(i => i.item=='dripEdge').itemCost,
                    description: 'Install drip edge around perimeter edges'
                },
                misc: { description: 'Includes all pipe boots and flashing where needed'},
                warranty: { description: 'Includes 5 year labor and material warranty' },
                decking: { description: `** Condition of decking is unknown until the removal of existing shingles, damaged decking will be replaced at the cost of $100 per 4'X8'' sheet (billed separate to owner)` },
            }
        },
    },
    watch: {
        perimeterLF() {
            this.estimate.dimensions.perimeterLF = this.perimeterLF || 0
        },
        squares() {
            this.estimate.dimensions.squares = Math.ceil(this.squares || 0)
        },
        selectedShingles() {
            this.updateShingleOptions();
        },
        availableMaterials() {
            console.log('watching availableMaterials')
            if (!this.availableMaterials?.length) return
            for (let item in this.estimate.materialItems) {
                let availableMaterials = this.availableMaterials.filter(m => m.itemId == item)
                // if (!availableMaterials.length) continue
                if (availableMaterials.length === 1 || !this.estimate.materialItems[item]) {
                    try {
                        this.estimate.materialItems[item] = availableMaterials[0].id;
                    } catch(e) {
                        console.log('bad item', item, availableMaterials, this.availableMaterials)
                    }
                }
            }
        },
        selectedSupplier() {
            console.log('watching selectedSupplier')
            if (!this.selectedSupplier?.id || !this.availableMaterials?.length) return

            this.updateShingleOptions()

            // update the available materials when the selected supplier changes and alert the user on which materials are not available from the new supplier
            let unavailableMaterials = Object.values(this.estimate.materialItems).filter(m => m && !this.availableMaterials.map(a => a.id).includes(m));
            console.log('unavailableMaterials', unavailableMaterials)
            if (unavailableMaterials.length > 0) {
                let message = `The following materials are not available from ${this.selectedSupplier?.title}: ${unavailableMaterials.map(m => m.toTitleCase('kebab')).join(', ')}`;
                Swal.fire({
                    icon: 'warning',
                    title: 'Unavailable Materials',
                    text: message,
                    confirmButtonText: 'OK'
                })
            }

        }
    }
}
</script>
<style lang="postcss">
  .modal-table-text {
    font-size: 0.5rem;
    /* line-height: .75rem; */
  }
  .sticky-first-column {
    @apply bg-slate-100 sm:bg-transparent left-0 sticky z-10
  }
  .column-header-text {
    font-size: 12px;
    line-height: 16px;
  }
  .row-text {
    font-size: 10px;
    line-height: 16px;
  }
  .border-thin {
    border-width: .5px;
  }

  /* style="width: 9rem; background-color: #71717a; text-transform: uppercase; font-size: 12px; line-height: 16px; color: white;" */
</style>
