<template>

    <div class="w-full flex flex-col justify-center items-center h-full relative">

        <KTWC />
        <AddressLockout />

        <div class="w-full relative">

            <div
                v-if="topEmoji"
                class="absolute right-0 top-0 w-full h-full bg-contain bg-no-repeat bg-right opacity-25"
                :style="`background-image: url(https://bcr-rep-portal.s3.amazonaws.com/uploads/${topEmoji}-min.png)`"
            />

            <div class="py-6 bg-gray-800 text-white w-full flex flex-col items-center text-2xl" :class="{'!py-16': !store.user?.shotgun?.length && store.user?.emoji?.length}">
                <div class="max-w-md w-full flex justify-between px-8">
                    <div>
                        <div class="text-gray-500 uppercase tracking-widest text-sm">User</div>
                        {{ store.user?.firstName ? store.user?.title : 'No Name' }}
                        <div class="flex space-x-2" v-if="topEmoji">
                            <div v-for="v, e in store?.user?.emoji?.reduce((a,e) => {a[e] += 1; return a}, {'🦁':0,'🐋':0,'🦣':0})" :key="v"><span v-if="v">{{ e }}<span class="text-base opacity-50">x{{v}}</span></span></div>
                            <!-- <div v-for="e in store?.user?.emoji">{{ e }}</div> -->
                        </div>
                    </div>
                    <div v-if="store.ktwc?.knock && store.ktwc?.contingency != undefined">
                        <div class="text-gray-500 uppercase tracking-widest text-sm">Conversion</div>
                        {{ ((store.ktwc.contingency/store.ktwc.knock)*100).toFixed(0) }}%
                    </div>
                </div>
            </div>
            <div v-if="store.user?.shotgun?.length" class="pb-6 bg-gradient-to-b from-gray-800 to-red-800 text-white w-full flex flex-col items-center text-2xl">
                <router-link to="/shotgun" class="max-w-md w-full px-8 mt-2 space-y-2 block">
                    <div class="text-gray-500 uppercase tracking-widest text-sm -mt-4">Shotgun</div>
                    <div v-for="user in store.user?.shotgun" :key="user.id" class="text-xs flex items-center">
                        <div class="w-6 h-6 rounded-full bg-gray-700 inline-block mr-2 flex items-center justify-center bg-gray-500 bg-center bg-cover" :style="`background-image: url(${user.image?.url})`">
                            <i class="fa fa-user text-white text-xs" v-if="!user.image?.url" />
                        </div>
                        <div>{{ user.title }}</div>
                    </div>
                </router-link>
            </div>
        </div>

        <div :class="[`from-${store.theme.color}-50`, {'opacity-40 pointer-events-none': creatingOpportunity}]" class="w-full flex-grow py-12 flex flex-col justify-center items-center bg-gradient-to-br">

            <div class="flex flex-col space-y-4 w-full mb-8 px-8 max-w-md">
                <div class="flex justify-between border-b pb-4 items-center">
                    <h3 class="text-lg leading-tight">Damage Address <span class="text-gray-400 text-sm">(Verify/Correct Below)</span></h3>
                    <button @click="fetchAddress" class="border shadow rounded px-2 py-1" :disabled="fetching" :class="{'bg-sky-100': fetching}" data-test="locationButton">
                        <i class="fa fa-location" :class="{'animate-spin': fetching}" />
                    </button>
                </div>
                <Address v-model="address" :placeholder="placeholder" :useLastAddress="true" />
            </div>

            <btn @click="createOpportunity" label="Start" dataTest="startButton"/>

        </div>

    </div>

</template>

<script>
import KTWC from '../layout/ktwc.vue'
import Address from '../layout/address.vue'
import axios from 'axios'
import { tomTomKey } from '../../../../config/config/settings.json'
import Swal from 'sweetalert2'
import formatAddress from '../../helpers/formatAddress.js'
import normalizeAddress from '../../../../config/helpers/normalizeAddress'
import AddressLockout from '../layout/addressLockout.vue'

export default {
    components: { KTWC, Address, AddressLockout },
    inject: ['store'],
    data() {
        return {
            entries: [],
            fetching: false,
            placeholder: 'Enter an Address',
            creatingOpportunity: false,
            opportunity: null,
            address: {
                formatted: null,
            },
            coords: {
                lat: null,
                lng: null,
                accuracy: null,
            },
        }
    },
    mounted() {

		if (this.opportunity?.address?.formatted) {
			this.address = this.opportunity.address
		}

        navigator.permissions.query({name:'geolocation'}).then(result => {
            if (result.state == 'granted') {
                navigator.geolocation.getCurrentPosition(this.storePositon, null, { enableHighAccuracy: true, timeout: 15000, maximumAge: 0 })
            } else if (result.state == 'prompt') {
                navigator.geolocation.getCurrentPosition(this.storePositon, null, { enableHighAccuracy: true, timeout: 15000, maximumAge: 0 })
            } else if (result.state == 'denied') {
                Swal.fire({
                    title: 'Location Required',
                    text: 'Please enable location services to proceed.',
                    icon: 'warning',
                })
            }
        });

    },
    computed: {
        topEmoji() {
            let emojiOptions = ['🦣','🐋','🦁']
            let emojiMap = {
                '🦣': 'mamoth',
                '🐋': 'whale',
                '🦁': 'lion',
            }
            for (let option of emojiOptions) {
                if (this.store.user?.emoji?.includes(option)) return emojiMap[option]
            }
        },
        // myConversionRate() {
        //     return this.store.ktwc?.contingency ? (this.store.ktwc?.contingency / this.store.ktwc?.knock) * 100 : 0
        // },
        statusLight() {
            return this.store.weeklyConversion >= this.store.averageConversion ? 'green' : 'red'
        }
    },
    methods: {
        storePositon(position) {
            this.coords.lat = position?.coords?.latitude
            this.coords.lng = position?.coords?.longitude
            this.coords.accuracy = position?.coords?.accuracy
        },
        async reverseGeocode({ latitude, longitude }) {

            // Call create to fetch without global headers
            let tempAxios = axios.create()
            delete tempAxios.defaults.headers.common['Authorization']

            let response = await tempAxios.get(`https://api.tomtom.com/search/2/reverseGeocode/${latitude}%2C${longitude}.json?key=${tomTomKey}`)
            let place = response?.data?.addresses?.[0]

            return place

        },
        async fetchAddress(attempts=0) {

            attempts = !isNaN(attempts) ? attempts : 0
            if (!attempts && this.fetching) return
            this.placeholder = 'Fetching Address'
            this.fetching = true

            let fetchingInterval = setInterval(() => {
				this.placeholder = this.placeholder.split('.').length > 5 ? 'Fetching Address' : `${this.placeholder}.`
			}, 300);

			navigator.geolocation.getCurrentPosition(
                async (position) => {
                    let {latitude, longitude, accuracy} = position.coords

                    // If the location is not accurate enough
                    console.log('accuracy', accuracy, attempts)

                    if (accuracy > 100) {
                        if (attempts < 2) {
                            // Wait one second
                            await new Promise(resolve => setTimeout(resolve, 1000))
                            this.fetchAddress(attempts+1)
                            return
                        } else {
                            Swal.fire({
                                title: 'Location Accuracy',
                                text: 'Your location is not accurate enough. You will have to enter the address manually.',
                                icon: 'warning',
                            })
                        }
                    }

                    this.coords.lat = position.coords.latitude
                    this.coords.lng = position.coords.longitude
                    this.coords.accuracy = accuracy

                    let place
                    try {
                        place = await this.reverseGeocode({ latitude, longitude })
                    } catch(e) {
                        console.log('e', e)
                        Swal.fire({
                            title: 'Location Error',
                            text: 'There was an error fetching your location. You will have to enter the address manually.',
                            icon: 'warning',
                        })
                        this.fetching = false
                    }

                    let address = formatAddress(place)
                    address = normalizeAddress(address)

                    if (accuracy > 100) {
                        delete address.number
                        delete address.street
                    }
                    this.address = address

                    this.fetching = false
                    this.placeholder = 'Enter an Address'
                    clearInterval(fetchingInterval)
                },
                error => {
                    console.log('error', error)
                    this.fetching = false
                    this.placeholder = 'Enter an Address'
                    clearInterval(fetchingInterval)
                },
                { enableHighAccuracy: true, timeout: 15000, maximumAge: 0 }
            )

        },
        async createOpportunity() {

            if (this.creatingOpportunity) return
            this.creatingOpportunity = true

            let branch = this.store.user?.branches?.[0]?.id

            if (!branch) {
                this.creatingOpportunity = false
                return Swal.fire({
                    title: 'Branch Required',
                    text: 'Your account has not been assigned a branch. Please contact your administrator.',
                    icon: 'warning',
                })
            }

            if (!this.address?.formatted) {
                this.creatingOpportunity = false
                return Swal.fire({
                    title: 'Address Required',
                    text: 'Please enter an address to proceed.',
                    icon: 'warning',
                })
            }

            let addressElements = ['number','street','city','stateCode']
            let missingElement = addressElements.find(e => !this.address[e])

            if (missingElement) {
                this.creatingOpportunity = false
                return Swal.fire({
                    title: 'Address Incomplete',
                    text: `Please enter a ${missingElement} to proceed.`,
                    icon: 'warning',
                })
            }


            if (this.address?.id || this.address.bcrid) {

                console.log('this.address?.id', this.address?.id)
                console.log('this.address.bcrid', this.address.bcrid)
                let addressBlacklisted = this.store.blacklist?.find(a => a.address?.id === this.address.id || a.address?.bcrid === this.address.bcrid)
                console.log('addressBlacklisted', addressBlacklisted)

                // if (addressBlacklisted) {

                //     this.creatingOpportunity = false
                //     return Swal.fire({
                //         title: `Address Blacklisted`,
                //         icon: 'warning',
                //         text: 'This address has requested not to be contacted.',
                //     })

                // }

                // if (existingOpportunity) {

                //     let approved = (await Swal.fire({
                //         title: `Opportunity Exists`,
                //         confirmButtonText: 'Continue',
                //         showCancelButton: true,
                //         confirmButtonColor: '#d33',
                //         icon: 'warning',
                //         text: 'A opportunity already exists for this address.',
                //     })).isConfirmed
                //     if (!approved) return

                // }

            }

            let response = await this.store.localDB.save({
                timestamps: {},
                branch,
                address: this.address,
                gpsCoordinates: this.coords
            })

            if (response?.warning) Swal.fire(response.title, response.warning, 'warning')
            if (response?.warnings) Swal.fire('WARNING:', response.warnings?.join(', '), 'warning')
            if (response?.error) Swal.fire(response.title, response.error, 'error')
            if (response?.stop) return this.creatingOpportunity = false
            if (response?.id) this.opportunity = response

            this.store.lastAddress = this.address

            this.creatingOpportunity = false
            this.$router.push(`/opportunities/${this.opportunity.id}/observations`)
        }
    }
}
</script>
