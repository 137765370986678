<template>

    <div class="w-full flex-grow py-12 px-8 flex flex-col">

		<h2 class="text-2xl pb-8">Insurance Info</h2>

		<div class="flex flex-col justify-start items-start space-y-4">

			<div class="flex flex-col space-y-2 w-full">
				<h3 class="text-sm opacity-70">Policy Number</h3>
				<input v-model="formData.policyNumber" type="text" class="px-3 py-2 border w-full">
			</div>

			<div class="flex flex-col space-y-2 w-full">
				<h3 class="text-sm opacity-70">Claim Number</h3>
				<input v-model="formData.claimNumber" type="text" class="px-3 py-2 border w-full">
			</div>

			<div class="flex flex-col space-y-2 w-full">
				<h3 class="text-sm opacity-70">Adjuster Name</h3>
				<input v-model="formData.adjusterName" type="text" class="px-3 py-2 border w-full">
			</div>

			<div class="flex flex-col space-y-2 w-full">
				<h3 class="text-sm opacity-70">Deductible</h3>
				<input v-model="formData.deductible" type="text" class="px-3 py-2 border w-full">
			</div>

			<div class="flex flex-col space-y-2 w-full">
				<h3 class="text-sm opacity-70">Notes</h3>
				<textarea v-model="formData.notes" class="px-3 py-2 border w-full resize-none" rows="5"></textarea>
			</div>

		</div>

		<button @click="next" :class="`bg-${store.theme.color}-500 border-${store.theme.color}-600`" class="text-xl px-4 py-2 rounded-xl text-white uppercase border-b-4 self-end mt-8">
            Submit
        </button>

    </div>

</template>

<script>
import Mango from '../../helpers/mango.js'
import confetti from 'canvas-confetti';

export default {
    props: ['opportunity'],
    inject: ['store'],
    data() {
        return {
            savingOpportunity: false,
			formData: {
				policyNumber: null,
				claimNumber: null,
				adjusterName: null,
				deductible: null,
				notes: null,
			}
        }
    },
    watch: {
        savingOpportunity() {
            this.$emit('saving', this.savingOpportunity)
        }
    },
    methods: {
        async next() {
            this.savingOpportunity = true
            let timestamps = this.opportunity.timestamps
            if (!timestamps.contingency) timestamps.contingency = new Date()
            await this.store.localDB.save({ id: this.opportunity.id, insuranceInfo: this.formData, timestamps })
            this.opportunity.insuranceInfo = this.formData
            this.opportunity.timestamps = timestamps
            this.savingOpportunity = false
            this.store.currentOpportunityPath = null
            this.$router.push(`/`)
            confetti({angle: 270, origin: {y:0}, spread: 180})
        }
    },
	mounted() {
		const savedInsuranceInfo = this.opportunity.insuranceInfo
		if (savedInsuranceInfo?.policyNumber) this.formData.policyNumber = savedInsuranceInfo.policyNumber
		if (savedInsuranceInfo?.claimNumber) this.formData.claimNumber = savedInsuranceInfo.claimNumber
		if (savedInsuranceInfo?.adjusterName) this.formData.adjusterName = savedInsuranceInfo.adjusterName
		if (savedInsuranceInfo?.deductible) this.formData.deductible = savedInsuranceInfo.deductible
		if (savedInsuranceInfo?.notes) this.formData.notes = savedInsuranceInfo.notes
	}
}
</script>
