<template>

    <div class="w-full flex-grow py-12 px-8 flex flex-col">

		<div class="flex flex-col justify-start items-start space-y-4">

			<div class="flex flex-col space-y-2 w-full">
				<h3 class="text-sm opacity-70">Verify Address</h3>
                <div class="border bg-gray-50 rounded-lg p-4">
                    <Address v-model="address" />
                </div>
			</div>

			<div class="flex flex-col space-y-2 w-full">
				<h3 class="text-sm opacity-70">Damage Type</h3>
				<input :value="damageType" type="text" class="px-3 py-2 border w-full cursor-pointer" readonly @click="editDamageType">
			</div>

			<div class="flex flex-col space-y-2 w-full">
				<h3 class="text-sm opacity-70">Damage Location</h3>
				<input :value="damageLocation" type="text" class="px-3 py-2 border w-full cursor-pointer" readonly @click="editDamageLocation">
			</div>

			<div class="flex flex-col space-y-2 w-full">
				<h3 class="text-sm opacity-70">Notes</h3>
				<textarea v-model="formData.notes" class="px-3 py-2 border w-full resize-none" rows="5"></textarea>
			</div>

		</div>

		<button @click="next" :class="`bg-${store.theme.color}-500 border-${store.theme.color}-600`" class="text-xl px-4 py-2 rounded-xl text-white uppercase border-b-4 self-end mt-8">
            Submit
        </button>

    </div>

</template>

<script>
import Mango from '../../helpers/mango.js'
import Address from '../layout/address.vue'

export default {
    components: { Address },
    props: ['opportunity'],
    inject: ['store'],
    data() {
        return {
            savingOpportunity: false,
			address: {
                formatted: null,
            },
			formData: {
				notes: null,
			}
        }
    },
	created() {
		if (this.opportunity.address?.formatted) this.address = this.opportunity.address
		const savedInfo = this.opportunity.leadInfo
		if (savedInfo?.notes) this.formData.notes = savedInfo.notes
	},
    watch: {
        savingOpportunity() {
            this.$emit('saving', this.savingOpportunity)
        }
    },
    methods: {
        async next() {
            this.savingOpportunity = true
			this.opportunity.id = (await this.store.localDB.save({ id: this.opportunity.id, address: this.address, leadInfo: this.formData }))?.id
			this.opportunity.address = this.address
            this.opportunity.leadInfo = this.formData
            this.savingOpportunity = false
            this.store.currentOpportunityPath = null
            this.$router.push(`/`)
        },
		editDamageType() {
			this.$router.push({ path: `/opportunities/${this.opportunity.id}/observations`, query: { return: 'opportunity-info' }})
		},
		editDamageLocation() {
			this.$router.push({ path: `/opportunities/${this.opportunity.id}/damage-location`, query: { return: 'opportunity-info' }})
		},
    },
	computed: {
		damageType() {
			return this.opportunity.damageType
		},
		damageLocation() {
			return this.opportunity.damageObserved?.locations?.join(', ')
		}
	}
}
</script>
