<template>
	<div class="relative pb-[4px]">
		<button
			class="relative z-10 w-full rounded-xl text-white uppercase"
			:class="[`bg-${store.theme.color}-500`, selected ? 'py-2 px-4' : 'py-4 px-6', selected ? `border-8 border-${store.theme.color}-400`: '', fontSize]"
      :data-test="dataTest"
		>
			{{ label }}
		</button>
		<div :class="`bg-${store.theme.color}-600`" class="absolute bottom-0 w-full text-3xl px-6 py-4 rounded-xl">&nbsp;</div>
		<div :class="`bg-${store.theme.color}-400`" class="absolute z-20 -top-2 -right-2 rounded-full flex items-center justify-center w-8 h-8 shrink-0 text-white" v-if="selected">
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="4" stroke="currentColor" class="w-4 h-4">
				<path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
			</svg>
		</div>
	</div>
</template>

<script>
	export default {
      inject: ['store'],
      props: {
        dataTest: {
          type: String,
        },
        label: {
          type: String,
        },
        selected: {
          type: Boolean,
          default: false,
        },
        fontSize: {
            type: String,
            default: 'text-2xl'
        }
		}
	}
</script>
