<template>
    <div v-if="closestBlacklistedAddress?.yards < 100" class="w-full p-2">
        <div class="rounded shadow-lg bg-yellow-200 p-4">
            <div class="text-lg font-bold"><i class="fad fa-exclamation-triangle" /> Warning</div>
            <div class="text-sm my-2">You are near a blacklisted address. DO NOT KNOCK the following address.</div>
            <div class="text-sm"><span class="uppercase font-semibold opacity-70">Address:</span> {{ closestBlacklistedAddress?.closest?.address?.address }}</div>
            <div class="text-sm"><span class="uppercase font-semibold opacity-70">Distance:</span> {{ closestBlacklistedAddress?.yards?.toFixed?.(0) }} yards</div>
        </div>
    </div>
</template>

<script>
import Mango from '../../helpers/mango'
export default {
    inject: ['store'],
    data() {
        return {
            blacklist: [],
            coordinates: {}
        }
    },
    async mounted() {
        await this.getBlacklist()
        await this.getCoordinates()
    },
    methods: {

        async getBlacklist() {
            // If last checked was less than 24hrs ago, use the cached blacklist
            if (localStorage.getItem('blacklistChecked') && (new Date() - new Date(localStorage.getItem('blacklistChecked'))) < 1*60*60*1000) {
                this.blacklist = JSON.parse(localStorage.getItem('blacklist'))
            } else {
                this.blacklist = await Mango.opportunities({search: {addressLockoutApproved: true}, limit: 1000, fields: ['address','gpsCoordinates']})
                localStorage.setItem('blacklist', JSON.stringify(this.blacklist))
                localStorage.setItem('blacklistChecked', new Date())
            }
            // Save in the store
            this.store.blacklist = this.blacklist
        },

        async getCoordinates() {
            let result = await navigator.permissions.query({name:'geolocation'})

            if (result.state == 'prompt') {
                navigator.geolocation.getCurrentPosition(() => {});
            } else if (result.state == 'denied') {
                Swal.fire({
                    title: 'Location Required',
                    text: 'Please enable location services to proceed.',
                    icon: 'warning',
                })
            }

            navigator.geolocation.getCurrentPosition((position) => {
                this.coordinates = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                }
            })
        }

    },
    computed: {
        closestBlacklistedAddress() {
            if (!Array.isArray(this.blacklist) || !this.blacklist.length) return
            let closest = this.blacklist.reduce((prev, curr) => {
                let prevDistance = Math.sqrt(Math.pow(prev.gpsCoordinates.lat - this.coordinates.lat, 2) + Math.pow(prev.gpsCoordinates.lng - this.coordinates.lng, 2))
                let currDistance = Math.sqrt(Math.pow(curr.gpsCoordinates.lat - this.coordinates.lat, 2) + Math.pow(curr.gpsCoordinates.lng - this.coordinates.lng, 2))
                return prevDistance < currDistance ? prev : curr
            })
            let distance = Math.sqrt(Math.pow(closest.gpsCoordinates.lat - this.coordinates.lat, 2) + Math.pow(closest.gpsCoordinates.lng - this.coordinates.lng, 2))
            let meters = distance * 111000
            let yards = meters * 1.09361
            return { yards, closest }
        }
    }
}
</script>

<style>

</style>
