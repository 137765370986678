<template>

    <div class="w-full flex-grow py-12 flex flex-col justify-center items-center">

        <!-- <h3 class="text-xl mb-12">Homeowner Response</h3> -->
        <h2 class="text-2xl pb-16">Homeowner Response</h2>

        <div class="flex flex-col space-y-8">

			<btn @click="next(option)" v-for="option in options" :key="option.label" :label="option.label" :selected="opportunity?.discussion?.toLowerCase() == option.label.toLowerCase()"></btn>

            <div class="text-center">
                <button @click="next({label: 'Lockout', uri: '/'})" class="underline text-lg">
                    <span><i class="fas fa-ban mr-2" />Block</span>
                    <!-- <span><i class="fas fa-lock mr-2" />Lockout</span> -->
                </button>
            </div>
        </div>

    </div>

</template>

<script>
import Swal from 'sweetalert2'
import Mango from '../../helpers/mango.js'

export default {
    props: ['opportunity'],
    inject: ['store'],
    data() {
        return {
            savingOpportunity: false,
            options: [
				{
					label: 'No Answer',
					uri: 'opportunity-info'
				},
				{
					label: 'Not Interested',
					uri: 'objections'
				},
                {
					label: 'Soft Set',
					uri: 'owner-info'
				},
                {
					label: 'Inspection',
					uri: 'inspect'
				}
            ]
        }
    },
    watch: {
        savingOpportunity() {
            this.$emit('saving', this.savingOpportunity)
        }
    },
    methods: {
        async next(option) {

            if (option.label == 'Lockout') {
                let approved = (await Swal.fire({
                    title: 'Are you sure?',
                    text: 'This will add the address to the lockout list.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, lockout!',
                    cancelButtonText: 'No, cancel!',
                    reverseButtons: true
                }))?.isConfirmed
                if (!approved) return
                await this.store.localDB.save({ id: this.opportunity.id, discussion: option.label, addressLockout: new Date() })
                return this.$router.push(`/`)
            }

            this.savingOpportunity = true
            let ktwc = {knock: 1, talk: option.label == 'No Answer' ? 0 : 1, walk: option.label == 'Inspection' ? 1 : 0}
            let timestamps = this.opportunity.timestamps
            if (ktwc.talk) {timestamps = {...timestamps, talk: new Date()}}
            if (ktwc.walk) {timestamps = {...timestamps, walk: new Date()}}
            if (option.label == 'Soft Set') {timestamps = {...timestamps, softSet: new Date()}}
            this.opportunity.id = (await this.store.localDB.save({ id: this.opportunity.id, discussion: option.label, ktwc, timestamps }))?.id
            this.opportunity.discussion = option.label
            this.opportunity.timestamps = timestamps
            this.savingOpportunity = false
            this.$router.push(`/opportunities/${this.opportunity.id}/${option.uri}`)
        }
    }
}
</script>
