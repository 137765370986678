<template>
    <label :for="_uid" :uploading="uploading" :error="error" :url="url" :class="{'block relative border-2 border-transparent': showProgress}" ref="container">

        <svg class="absolute -left-[2px] -top-[2px] z-50 w-[calc(100%+4px)] h-[calc(100%+4px)]" :style="progressRingStyle" v-if="progress">
            <!-- <circle class="progress-ring__circle" stroke="green" stroke-width="4" fill="transparent" r="58" cx="60" cy="60"/> -->
            <circle class="progress-ring__circle" stroke="#22c55e" :stroke-width="progressWidth" fill="transparent" :r="radius" :cx="containerSize/2" :cy="containerSize/2" :style="progressRingStyle"/>
        </svg>

        <button type="button" v-if="showDelete" @click.stop.prevent="url = null; $emit('url', null)" class="absolute -top-1 -right-1 w-[0.9rem] h-[0.9rem] rounded-full border-2 border-black/50 dark:bg-red-900 bg-red-500 flex items-center justify-center text-4xs font-bold z-50">
            <span class=""><i class="fa fa-times opacity-50"/></span>
        </button>

        <slot :progress="progress" />

        <input
            :id="_uid"
            hidden
            type="file"
            :accept="accept"
            @change="upload"
        />
    </label>
</template>

<script>
import Compress from 'compress.js'
const compress = new Compress()
import {apiDomain} from '../../../config/config/settings.json'
// import Modernizr from 'modernizr'

export default {
    props: {
        accept: {type: String, default: 'image/png, image/jpeg, image/heic'},
        showProgress: {type: Boolean, default: true},
        miultiple: {type: Boolean, default: true},
        showDelete: {type: Boolean, default: true},
    },
    data() {
        return {
            _uid: Math.random().toString(36).substr(2, 10),
            uploading: false,
            error: false,
            url: null,
            progress: 0,
            filename: null,
            containerSize: 0,
            progressWidth: 2,
        }
    },
    watch: {
        uploading() { this.$emit('uploading', this.uploading) },
        error() { this.$emit('error', this.error) },
        url() { this.$emit('url', this.url) },
        filename() { this.$emit('filename', this.filename) },
        // progress() { this.$emit('progress', this.progress) },
    },
    mounted() {
        this.$nextTick(() => {
            this.containerSize = this.$refs.container.offsetWidth;
        })
    },
    computed: {
        radius() {
            return ((this.containerSize / 2)-(this.progressWidth/2)) || 0; // Subtract the stroke width
        },
        progressRingStyle() {
            const circumference = 2 * Math.PI * this.radius;
            const offset = circumference - (this.progress / 100) * circumference;
            return {
                strokeDasharray: `${circumference} ${circumference}`,
                strokeDashoffset: offset,
            };
        },
    },
    methods: {
        async upload(event) {

            let file = event.target.files[0]
            const formData = new FormData()

            this.uploading = true
            this.filename = file.name

            // // Use Modernizr to detect if exit-orientation is supported.
            // const browserSupportsExifOrientation = () => {
            //     return new Promise((resolve) => Modernizr.on("exiforientation", resolve));
            // };

            // // Only rotate if browser does not support exit orientation.
            // const shouldRotate = async () => {
            //     const supported = await browserSupportsExifOrientation();
            //     return !supported;
            // };

            // const rotate = await shouldRotate();

            // Compress the image
            if (file.type.includes('image')) {
                let results = await compress.compress([file], {
                    quality: .75, // the quality of the image, max is 1,
                    maxWidth: 1920, // the max width of the output image, defaults to 1920px
                    maxHeight: 1920, // the max height of the output image, defaults to 1920px
                    resize: true, // defaults to true, set false if you do not want to resize the image width and height
                    rotate: false, // See the rotation section below
                })
                const img1 = results[0]
                const base64str = img1.data
                const imgExt = img1.ext
                const filename = file.name
                file = Compress.convertBase64ToFile(base64str, imgExt)
                file = new File([file], filename, { type: file.type });
                console.log('file', file)
            }

            formData.append('file', file)

            const xhr = new XMLHttpRequest()

            xhr.open('POST', `${apiDomain}/upload`, true)

            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    this.progress = (event.loaded / event.total) * 100
                }
            }

            xhr.onload = () => {
                if (xhr.status === 200) {
                    const json = JSON.parse(xhr.response)
                    const path = json.paths[0]
                    const url = apiDomain + path
                    this.uploading = false
                    this.url = url
                    this.progress = 0
                } else {
                    this.error = 'Error while uploading file'
                    this.uploading = false
                }
            }

            xhr.onerror = () => {
                this.error = 'Error while uploading file'
                this.uploading = false
            }

            xhr.send(formData)
        },
    }
}
</script>

<style scoped>
    .progress-ring__circle {
        transition: stroke-dashoffset 0.35s;
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
    }
</style>
