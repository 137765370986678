<template>
    <div
        @click.prevent.stop="$emit('hide')"
        v-show="active"
        class="
            fixed w-full h-screen bg-gray-800/50 dark:bg-black/60 backdrop-blur-sm md:backdrop-blur-md
            flex items-start sm:items-center justify-center z-[100] inset-0 !m-0 overscroll-none cursor-default
        "
    >
        <div
            @click.stop
            class="shadow-card rounded w-full p-4 md:p-8 m-2 dark:bg-gray-800 bg-white
            border dark:border-gray-700 space-y-4 md:space-y-8 max-h-[75vh]"
            :class="[dialogClasses, maxWidth, allowOverflow ? 'overflow-visible' : 'overflow-y-scroll']"
        >
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        active: {default: true},
        allowOverflow: {type: Boolean, default: false},
        maxWidth: {default: 'max-w-md'},
        dialogClasses: {type: String, default: ''},
    },
    watch: {
        active: {
            handler() {
                if (this.active) this.clench()
                else this.release()
            },
            immediate: true
        }
    },
    mounted() {
        // Hide on escape key
        document.addEventListener('keydown', e => {
            if (e.key === 'Escape') this.$emit('hide')
        })
    },
    unmounted() {
        this.release()
        // remove listener
        document.removeEventListener('keydown', e => {
            if (e.key === 'Escape') this.$emit('hide')
        })
    },
    methods: {
        clench() {
            if (!this.small) document.getElementsByTagName('body')[0].className += ' overflow-hidden h-screen'
        },
        release() {
            let bodyClasses = document.getElementsByTagName('body')[0].className
            if (!this.small) document.getElementsByTagName('body')[0].className = bodyClasses.replaceAll(' overflow-hidden h-screen', '')
        }
    }
}
</script>
