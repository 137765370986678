<template>
    <div class="p-4 py-8 space-y-8 w-full max-w-md mx-auto relaltive" :class="{'opacity-40 pointer-events-none': saving}">

        <div class="text-center">
            <h2 class="text-2xl">Shotgun Mode</h2>
            <div v-if="shotgun.length" class="text-sm opacity-50">Currently riding with...</div>
            <div v-else class="text-sm opacity-50">Select a rep to start a group</div>
        </div>

        <!-- <div>
            <div v-for="user in store.user.shotgun" :key="user.id">
                {{ user.title }}
            </div>
        </div> -->

        <div class="">
            <MultiSelect
                collection="members"
                @selectedEntries="save"
                :algoliaFilters="algoliaFilters"
                v-model:modelSelectedEntries="store.user.shotgun"
                placeholder="Add Sales Rep"
            />
        </div>

        <div v-if="shotgun.length" class="w-full flex justify-center">
            <button @click="store.user.shotgun = []; save()" class="text-red-500 underline">Leave Group</button>
        </div>

        <div class="w-full flex justify-center">
            <button @click="checkStatus" class="text-sky-500 px-3 py-2 border border-sky-500 rounded-xl bg-sky-50/50">Refresh <i class="fad fa-sync" /></button>
        </div>

        <div v-if="!store.online" class="absolute h-full w-full inset-0 pointer-events-none bg-white/50 backdrop-blur-sm flex items-center justify-center">
            <div class="-rotate-6 text-4xl font-black uppercase tracking-2xl opacity-50">Offline</div>
        </div>

    </div>
</template>

<script>
import Swal from 'sweetalert2'
import Mango from '../../helpers/mango'
import MultiSelect from '../../helpers/multiSelect.vue'

export default {
	components: { MultiSelect },
    inject: ['store','axios'],
    data() {
        return {
            saving: false,
            statusChecker: null
        }
    },
    // watch: {
    //     shotgun(a,b) {
    //         if (a?.map(a => a?.id).join() != b?.map(b => b?.id).join()) this.save()
    //     }
    // },
    created() {
        this.store.user.shotgun = this.store.user.shotgun || []
        // this.statusChecker = setInterval(this.checkStatus, 2.5*1000);
    },
    beforeRouteLeave() {
        // clearInterval(this.statusChecker)
    },
    methods: {
        async checkStatus() {
            let response = (await this.axios.get(`${this.store.api}/controllers/ktwcStats`))?.data
            this.store.user.shotgun = response.shotgun
        },
        async save() {
            this.saving = true
            let response = await Mango.members.save({id: this.store.user.id, shotgun: this.shotgun})
            if (response?.includes?.('(shotgun)')) Swal.fire({icon: 'warning', title: 'Error', text: response.split(') ')[1]})
            this.store.user.shotgun = response?.shotgun || []
            this.saving = false
        }
    },
    computed: {
        shotgun() {
            return this.store.user?.shotgun
        },
        algoliaFilters() {
            let branchIds = this.store.user.branches.map(branch => `branches.id:${branch.id}`).join(' OR ');
            let algoliaFilters = `${branchIds} AND NOT objectID:${this.store.user.id}`;
            let shotgun = this.store.user?.shotgun || []
            if (this.store.user.shotgun?.length) {
                algoliaFilters += ` AND NOT objectID:${shotgun.map(s => s.id).join(' AND NOT objectID:')}`
            }
            return algoliaFilters
        }
    }
}
</script>

<style>

</style>
