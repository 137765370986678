<template>

    <div class="max-w-2xl mx-auto">

        <div>
            <iframe
                class="aspect-video w-full md:rounded-lg"
                src="https://www.youtube.com/embed/KKe-7gYM03w?si=8OaXBsGQVm1hw3E9"
                allowfullscreen
            />
        </div>

        <div class="flex mt-8 w-full justify-between space-x-4 sm:space-x-8 px-4">
            <div class="rounded-lg bg-gray-100 p-4 sm:p-6 flex flex-col items-center w-1/2 border justify-between">
                <h2 class="mb-4 font-semibold text-center text-sm">How to Update KTWC Numbers</h2>
                <iframe
                    class="aspect-[9/16] w-full max-w-48 rounded-lg"
                    src="https://www.youtube.com/embed/navsujD_SBs?si=8OaXBsGQVm1hw3E9"
                    allowfullscreen
                />
            </div>
            <div class="rounded-lg bg-gray-100 p-4 sm:p-6 flex flex-col items-center w-1/2 border justify-between">
                <h2 class="mb-4 font-semibold text-center text-sm">Approvals, Contracts, Revenue, and Soft Sets</h2>
                <iframe
                    class="aspect-[9/16] w-full max-w-48 rounded-lg"
                    src="https://www.youtube.com/embed/Zv94rX1qkJM?si=8OaXBsGQVm1hw3E9"
                    allowfullscreen
                />
            </div>
        </div>

        <div class="text-sm space-y-4 p-4 border-t pt-8 mt-8">
            <div v-for="q,i in questions" :key="i">
                <div class=" mb-2">{{ q.question }}</div>
                <div class="font-thin" v-html="q.answer" />
            </div>
        </div>

    </div>

</template>

<script>
export default {
    data() {
        return {
            questions: [
                // {
                //     question: 'How can I mark an existing opportunity as a soft set?',
                //     answer: 'To mark an opportunity as a soft set, find the opportunity in the "Opportunities" tab and click the "Edit" button to walk through the steps again.'
                // },
                // {
                //     question: 'How can I mark an existing opportunity as a contingency?',
                //     answer: 'To mark an opportunity as a contingency, find the opportunity in the "Opportunities" tab and click the "Edit" button to walk through the steps again.'
                // },
                {question: `Why isn't my GPS button working?`,
                answer: `<p>If geolocation is not working for a rep, they need to make sure that location services are enabled, and precise location is enabled.</p>
                <p class="underline mt-4">Android:</p>
                <p>Settings -&gt; Location -&gt;&nbsp; App location permissions -&gt; Chrome (Allow and allow precise)</p>
                <p>Also in chrome Settings -&gt; Site settings -&gt; Location -&gt; Make sure app.bestchoiceroofing.com is in the allowed list</p>
                <p class="underline mt-4">iPhone:</p>
                <p>Setting -&gt; Privacy & Security -&gt; Location Services -&gt; Safari (While using and allow precise location)</p>`,
                },
                {question: `How can I mark an opportunity as soft set or contingency?`,
                answer: `<p>This should be done in the initial collection of the address through the "Homeowner Response" or "Inspection" screens. If the opportunity has already been created, you'll need to find it in "my opportunities", click the address of the opportunity you'd like to update and toggle the "ctgy" switch for contingency, or set a soft set date/time to mark as "Soft Set".</p>`,
                },
                {question: `How does Shotgun Mode work?`,
                answer: `<p>To use shotgun mode, tap the shotgun tab. From there you can search for all employees in your branch. Tap the employee you're riding with and they will be added to your group. You can add more than one person to your group. Only one member needs to organize the group, and the Shotgun settings will propagate across all users in the group.</p>`,
                },
                {question: `Why was this opportunity included or excluded from numbers?`,
                answer: `<p>Any opportunity updated since the last cutoff (yesterday at 7:30pm) is considered for nightly numbers. Any properties that were changed since the cutoff will have an effect on the report. For instance, if an opportunity was created last week, and today insurance was accepted, the KTWC from that lead will not effect tonight's report, but it will count towards the number of accepted opportunities. If you need another knock counted for that opportunity, hit the edit button and go through the steps to hit "knock" again. If revenue was reported last week for $10k, and today you change it to $12k, $2k will be added to revenue in tonight's report.</p>`,
                },
                {question: `How should we handle non-knocks (canvassing)?`,
                answer: `<p>Add the opportunity as you normally would, but when you come to the "Knock" screen hit "Skip" at the bottom. This will queue the address to be sent to Storm Leads, but it will not count as a knock.</p>`,
                },
                {question: `Can you explain the interaction of the app with Storm Leads?`,
                answer: `<p>Any addresses collected since the cutoff that did not go to contingency will be sent to Storm Leads that night. If you modify an existing opportunity after it has been sent, it will not be sent a second time.</p>`,
                },
                {
                    question: 'How can I add revenue to an opportunity?',
                    answer: 'You can add revenue on the "Opportunities" tab. Search for the opportunity you want using the search bar or advanced search cog, then enter the amount.'
                },
                {
                    question: 'Which opportunities will be submitted to Storm Leads?',
                    answer: `<p>Any opportunity created since the last cutoff (yesterday at 7:30pm) will be submitted to Storm Leads unless it is marked as a contingency.</p>`
                },
                {
                    question: `Can corrections be made to an opportunity's shotgun status after it has been created?`,
                    answer: `<p>Your branch manager can add or remove reps from an opportunity through their admin dashboard.</p>`
                },
                {
                    question: 'What if I don’t have service and the app won’t pull up an address?',
                    answer: `<p>If you don't have a solid internet connection and the geolocate button is unable to fetch your current address, you can click on the address fields and input the data manually.</p>`
                },
                {
                    question: 'Does shotgun mode work with more than two people? ',
                    answer: `<p>There is no limit to the number of people who can be linked through shotgun mode.</p>`
                },
                {
                    question: 'Is there a way to look up opportunities by status?',
                    answer: `<p>You can filter opportunities by status in the "my opportunities" tab by selecting the advanced filters icon in the top right. Under advanced filters, there is an option to filter by status.</p.</p>`
                },
                {
                    question: 'How can I mark an opportunity as an approval?',
                    answer: 'You can mark approval on the "Opportunities" tab. Search for the opportunity you want and toggle the "Insurance Accepted" switch.'
                },
                {
                    question: `Who to contact for further help?`,
                    // answer: `<p><a href="dale.stanley@bestchoiceroofing.com" class="underline text-blue-500">dale.stanley@bestchoiceroofing.com</a></p><p><a href="max.oldham@bestchoiceroofing.com" class="underline text-blue-500">max.oldham@bestchoiceroofing.com</a></p>`
                    answer: `
                        <p><a href="mailto:it.support@bestchoiceroofing.com" class="underline text-blue-500">it.support@bestchoiceroofing.com</a></p>
                        <p><a href="mailto:matthew.estes@bestchoiceroofing.com" class="underline text-blue-500">matthew.estes@bestchoiceroofing.com</a></p>
                    `
                },
            ]
        }
    }
}
</script>

<style>

</style>
