<template>

    <div class="w-full flex-grow p-12 flex flex-col justify-start items-start">

        <!-- <h2 class="text-2xl pb-16">Damage Observed</h2> -->

        <div class="flex flex-col space-y-2 pb-12">
            <h3 class="text-lg">Where's the Damage?</h3>
			<label class="flex space-x-4 items-center">
                <input v-model="allLocations" type="checkbox" @change="updateLocations" />
                <div>All</div>
            </label>
            <label v-for="option in damageLocations" :key="option" class="flex space-x-4 items-center">
                <input v-model="formData.locations" type="checkbox" :value="option" />
                <div>{{ option }}</div>
            </label>
        </div>

        <div class="flex flex-col space-y-2">
            <h3 class="text-lg">What's Damaged?</h3>
            <label v-for="option in damagedItems" :key="option" class="flex space-x-4 items-center">
                <input v-model="formData.items" type="checkbox" :value="option" />
                <div>{{ option }}</div>
            </label>
        </div>

        <button @click="next" :class="`bg-${store.theme.color}-500 border-${store.theme.color}-600`" class="text-xl px-4 py-2 rounded-xl text-white uppercase border-b-4 self-end mt-16">
            Continue
        </button>

    </div>

</template>

<script>
import Mango from '../../helpers/mango.js'

export default {
    props: ['opportunity'],
    inject: ['store'],
    data() {
        return {
            savingOpportunity: false,
			allLocations: false,
            formData: {
                locations: [],
                items: []
            },
            damageLocations: [
                'Front',
                'Left',
                'Right',
                'Back',
            ],
            damagedItems: [
                'Shingles',
                'Collateral',
            ]
        }
    },
	watch: {
		formData: {
			handler() {
				if (this.formData.locations.length === this.damageLocations.length) {
					this.allLocations = true
				} else {
					this.allLocations = false
				}
			},
			deep: true
		},
        savingOpportunity() {
            this.$emit('saving', this.savingOpportunity)
        }
	},
    methods: {
		updateLocations() {
			if (this.allLocations) {
				this.formData.locations = this.damageLocations
			} else {
				this.formData.locations = []
			}
		},
        async next() {
            this.savingOpportunity = true
            this.opportunity.id = (await this.store.localDB.save({ id: this.opportunity.id, damageObserved: this.formData }))?.id
            this.opportunity.damageObserved = this.formData
            this.savingOpportunity = false

			const params = new URLSearchParams(window.location.search)
			const uri = params.has('return') ? params.get('return') : 'selfie'
            this.$router.push(`/opportunities/${this.opportunity.id}/${uri}`)
        }
    },
	mounted() {
		const savedDamage = this.opportunity.damageObserved
		if (savedDamage?.locations.length) this.formData.locations = savedDamage.locations
		if (savedDamage?.items.length) this.formData.items = savedDamage.items
	}
}
</script>
