<template>
    <div class="w-full flex flex-col justify-center items-center h-full relative">

        <KTWC />

        <div ref="container" :class="[`from-${store.theme.color}-50`, {'opacity-40 pointer-events-none': saving}]" class="w-full h-full overflow-y-scroll bg-gradient-to-br">
            <Mango :main="true" v-slot="{data: opportunity}" class="w-full h-full relative">

                <div v-if="opportunity?.address" class="flex justify-between items-center border-b bg-gray-100 h-14 px-8 sticky top-0 shadow z-40">
                    <button class="text-blue-500" @click="$router.go(-1)">
                        <i class="fa fa-chevron-left mr-2" />Back
                    </button>
                    <div>
                        <i class="fas fa-map-marker-alt mr-2" />
                        {{ opportunity.address?.address || opportunity.address?.formatted?.split?.(',')?.[0] || opportunity.address }}
                    </div>
                </div>

                <router-view :opportunity="opportunity" class="max-w-lg mx-auto" v-if="opportunity" @saving="saving = $event" />

            </Mango>
        </div>

    </div>
</template>

<script>
import KTWC from '../layout/ktwc.vue'

export default {
    components: { KTWC },
    inject: ['store'],
    data() {
        return {
            saving: false,
        }
    },
    watch: {
        $route() {
            // Scroll to top of page when route changes
            this.$refs.container.scroll({top: 0})
        }
    }
}
</script>
