<template>

    <div class="w-full flex-grow py-12 flex flex-col justify-center items-center">

        <h3 class="text-xl mb-12">Inspection Response</h3>

        <div class="flex flex-col space-y-8 px-4">
			<btn
                @click="next(option)"
                v-for="option in options"
                :key="option.label"
                :label="option.label"
                :selected="opportunity?.inspection?.toLowerCase() == option.label.toLowerCase()"
            />
        </div>

    </div>

</template>

<script>
import Mango from '../../helpers/mango.js'

export default {
    props: ['opportunity'],
    inject: ['store'],
    data() {
        return {
            savingOpportunity: false,
            options: [
                {
					label: 'Insufficient Damage',
					uri: 'owner-info'
				},
				{
					label: 'Not Interested',
					uri: 'objections'
				},
                {
					label: 'Soft Set',
					uri: 'owner-info'
				},
                {
					label: 'Contingency',
					uri: 'owner-info'
				},
            ]
        }
    },
    watch: {
        savingOpportunity() {
            this.$emit('saving', this.savingOpportunity)
        }
    },
    methods: {
        async next(option) {
            this.savingOpportunity = true
            let ktwc = {knock: 1, talk: 1, walk: 1, contingency: option.label != 'Contingency' ? 0 : 1}
            // if (ktwc.contingency) this.store.ktwc.contingency += 1
            let timestamps = this.opportunity.timestamps
            if (option.label == 'Soft Set') {timestamps = {...timestamps, softSet: new Date()}}
            if (option.label == 'Contingency') {timestamps = {...timestamps, contingency: new Date()}}
            this.opportunity.id = (await this.store.localDB.save({ id: this.opportunity.id, inspection: option.label, ktwc, timestamps }))?.id
            this.opportunity.inspection = option.label
            this.savingOpportunity = false
            if (option.label == 'Not Enough Damage') return this.$router.push(`/`)
            this.$router.push(`/opportunities/${this.opportunity.id}/${option.uri}`)
        }
    }
}
</script>
