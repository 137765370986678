import { ref } from 'vue'
import Mango from './mango'

// Function for setting cookies
let setCookie = function (cname, cvalue) {
    var d = new Date();
    d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

const getUser = async (user, loginUserId) => {

    user = user || { value: {} }

    // Local since server won't provide user
    let local = window.location.href.includes('localhost')

    if (local || loginUserId) {

        let userId = window.localStorage.getItem('user')

        if (!userId) return {}
        user.value = await Mango.member(userId, { depthLimit: 1 }).then(r => user.value = r)

    } else if (window.user?.id) {

        console.log('got it!')
        user.value = window.user

        let member = user.value
        let auth = `${member.password?.hash}:${member.id}`

        window.localStorage.setItem('user', member.id)
        window.localStorage.setItem('token', member.token)
        window.localStorage.setItem('email', member.email)
        window.localStorage.setItem('auth', auth)

        setCookie(`Authorization`, auth)

        /*
        Gotta clear this out so next time
        we try to call getUser we don't get
        stale data. ;)

        Do we tho? In this instatnce I think it's
        only being called in the context of a login
        where loginUserId is passed in.
        */

        // window.user = null

        return user.value

    } else {

        // Logout if credentials are bad
        user.value = {}
        window.localStorage.removeItem('user')
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('email')
        window.localStorage.removeItem('auth')
        setCookie(`Authorization`, ``)

    }

    if (!user.value.address) user.value.address = {}
    if (!user.value.shotgun) user.value.shotgun = []

    return user.value

}

const initUser = () => {

    const user = ref({})
    getUser(user)
    return user

}

export { initUser, getUser }
