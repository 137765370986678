<template>
    <div class="relative">
        <input
            v-model="address.formatted"
            type="text"
            class="px-3 py-2 border w-full bg-white dark:bg-transparent"
            placeholder="Address"
            @change="getAddress"
        />
        <!-- @input="debounceAutocomplete" -->
        <!-- <div class="absolute bottom border-x border-b rounded-b-lg shadow-card w-full z-50 bg-white overflow-scroll" v-show="autocompleteResults.length">
            <button
                v-for="address in autocompleteResults" :key="address.id"
                class="hover:bg-sky-50 px-4 py-2 w-full text-left"
                @click="selectAddress(address)"
            >
                {{ address.formattedAddress }}
            </button>
        </div> -->
    </div>
</template>

<script>
// import Radar from 'radar-sdk-js';
// import 'radar-sdk-js/dist/radar.css';
import { radarPublishable, tomTomKey } from '../../../../config/config/settings.json'
import formatAddress from '../../helpers/formatAddress';
import axios from 'axios';

// Radar.initialize(radarPublishable);

let address = {
    address: null,
    city: null,
    state: null,
    zip: null,
    country: null,
    formatted: null,
    raw: null,
    coordinates: {
        lat: null,
        lng: null,
    },
}

export default {
    props: ['modelValue'],
    data() {
        return {
            debounceTimer: null,
            coordinates: null,
            autocompleteResults: [],
            address
        }
    },
	watch: {
        modelValue: {
            handler: function (address) {
                this.address = address
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        async forwardGeocode({ query }) {

            let tempAxios = axios.create()
            delete tempAxios.defaults.headers.common['Authorization']

            let response = await tempAxios.get(`https://api.tomtom.com/search/2/geocode/${query}.json?key=${tomTomKey}`)
            let place = response?.data?.results?.[0]

            return place

        },
        async getAddress() {
            // let radarAddress = await Radar.forwardGeocode({ query: this.address.formatted })
            // console.log('radarAddress', radarAddress)
            // let place = radarAddress?.addresses?.[0]
            let place = await this.forwardGeocode({ query: this.address.formatted })
            this.address = formatAddress(place)
            this.$emit('update:modelValue', this.address)
        },
        selectAddress(address) {
            this.address = formatAddress(address)
            this.autocompleteResults = []
            this.$emit('update:modelValue', this.address)
        },
        // async autocomplete() {
        //     this.autocompleteResults = (await Radar.autocomplete({
        //         query: this.address?.formatted,
        //         near: this.coordinates || undefined,
        //         limit: 5
        //     }))?.addresses || []
        // },
    },
    computed: {
        // debounceAutocomplete() {
        //     return
        //     return (...args) => {
        //         // savingCart.value = true
        //         clearTimeout(this.debounceTimer);
        //         this.debounceTimer = setTimeout(() => { this.autocomplete.apply(this, args) }, 500);
        //     }
        // }
    }
}
</script>

<style>

</style>
