<template>
    <div class="py-16 px-8 flex flex-col items-center" :class="{'opacity-40 pointer-events-none': saving}">

        <h2 class="text-4xl mb-2 text-center">Account</h2>
        <div class="text-2xs text-center mb-16 bg-gray-200 rounded px-2 py-1 font-mono opacity-80">{{ store.user.email }}</div>

        <div v-if="store.user" class="flex flex-col items-center justify-center space-y-6 w-full max-w-md mx-auto">
            <UploadFile
                @url="store.user.image = $event"
                class="w-40 h-40 rounded-full border-2 dark:border-gray-600 bg-gray-300 dark:bg-gray-700 block cursor-pointer mb-8"
                :showDelete="userImage"
            >
                <div v-if="!userImage" class="w-full h-full flex flex-col items-center justify-center">
                    <i class="fa fa-user text-3xl" />
                    <div class="text-xs mt-2">Upload Image</div>
                </div>
                <div
                    v-else class="w-full h-full bg-cover bg-center rounded-full group relative overflow-hidden"
                    :style="`background-image: url('${userImage}')`"
                >
                    <div class="w-full h-full hidden group-hover:flex absolute backdrop-blur items-center justify-center">
                        <i class="fa fa-pencil text-3xl text-gray-800" />
                    </div>
                </div>
            </UploadFile>
            <div class="space-y-4 w-full">
                <div class="flex flex-col space-y-1 w-full">
                    <h3 class="text-sm opacity-70">First Name</h3>
                    <input v-model="store.user.firstName" type="text" class="px-3 py-2 border w-full">
                </div>

                <div class="flex flex-col space-y-1 w-full">
                    <h3 class="text-sm opacity-70">Last Name</h3>
                    <input v-model="store.user.lastName" type="text" class="px-3 py-2 border w-full">
                </div>

                <div class="flex flex-col space-y-1 w-full">
                    <div class="flex space-x-2 items-center">
                        <h3 class="text-sm opacity-70">Password</h3>
                        <span class="text-xs opacity-50">(Leave blank to keep existing password.)</span>
                    </div>
                    <input v-model="password" type="password" class="px-3 py-2 border w-full">
                </div>

                <div class="flex flex-col space-y-1 w-full">
                    <h3 class="text-sm opacity-70">Phone Number</h3>
                    <input v-model="store.user.phone" type="tel" class="px-3 py-2 border w-full">
                </div>

                <div v-if="store.user.address" class="flex flex-col space-y-2 w-full">
                    <h3 class="text-sm opacity-70">Home Address</h3>
                    <div class="border bg-gray-50 rounded-lg p-4">
                        <Address v-model="store.user.address" />
                    </div>
                </div>

                <!-- <div class="flex flex-col space-y-1 w-full">
                    <h3 class="text-sm opacity-70">Birthday</h3>
                    <input @blur="store.user.birthday = $event.target.value" :value="store.user.birthday ? dayjs(store.user.birthday?.split('T')[0]).format('YYYY-MM-DD') : null" type="date" class="px-3 py-2 border w-full">
                </div> -->

            </div>
            <div>
                <button @click="updateUser" class="px-3 py-2 rounded bg-green-500 text-white">Save</button>
            </div>
        </div>
        <Login v-slot="{logout}" class="!p-0 !w-auto flex justify-center mt-16">
            <button class="py-1 border-b-2" @click="logout">Logout</button>
        </Login>

        <div v-if="store.nativeApp" class="mt-16">
            <button @click="deleteAccount" class="underline text-red-500 text-xs">Delete my Account</button>
        </div>

    </div>
</template>

<script>
import Mango from '../../helpers/mango'
import UploadFile from '../../helpers/uploadFile.vue'
import Address from '../layout/address.vue'
import Login from '../layout/login.vue'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'

// Function for setting cookies
let setCookie = function (cname, cvalue) {
    var d = new Date();
    d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export default {
    components: { Login, UploadFile, Address },
    inject: ['store','axios'],
    data() {
        return {
            password: null,
            url: this.store?.user?.image?.url,
            saving: false,
        }
    },
    created() {
        if (this.store.user && !this.store.user.address) this.store.user.address = {}
    },
    methods: {
        dayjs,
        async updateUser() {
            this.saving = true
            let data = {
                id: this.store.user.id,
                firstName: this.store.user.firstName,
                lastName: this.store.user.lastName,
                image: this.store.user.image,
                phone: this.store.user.phone,
                // birthday: this.store.user.birthday,
                address: this.store.user.address,
                status: 'open'
            }
            if (this.password) data.password = this.password
            let response = await Mango.members.save(data)
            console.log('response', response)
            if (response?.id) {
                if (this.password) {
                    if (response?.password?.hash) {
                        // Logout if credentials are bad
                        window.localStorage.setItem('token', response.password.hash)
                        window.localStorage.setItem('auth', `${response.password.hash}:${response.id}`)
                        setCookie(`Authorization`, `${response.password.hash}:${response.id}`)
                        this.axios.defaults.headers.common['Authorization'] = `${response.password.hash}:${response.id}`
                    } else {
                        window.localStorage.removeItem('user')
                        window.localStorage.removeItem('token')
                        window.localStorage.removeItem('email')
                        window.localStorage.removeItem('auth')
                        setCookie(`Authorization`, ``)
                        window.location.reload()
                    }
                }
                Swal.fire('Success', 'User updated!', 'success')
            }
            else Swal.fire('Error', 'Something went wrong...', 'error')
            this.saving = false
        },
        async deleteAccount() {
            Swal.fire({
                title: 'Are you sure?',
                text: 'This will delete your account and all associated data.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete my account!',
                cancelButtonText: 'No, keep my account.'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let response = await Mango.members.save({status: 'deactivated', id: this.store.user.id})
                    if (response?.success) {
                        window.localStorage.removeItem('user')
                        window.localStorage.removeItem('token')
                        window.localStorage.removeItem('email')
                        window.localStorage.removeItem('auth')
                        setCookie(`Authorization`, ``)
                        window.location.reload()
                    }
                }
            })
        }
    },
    computed: {
        userImage() { return this.store?.user?.image?.url || (typeof(this.store?.user?.image) == 'string' ? this.store?.user?.image : null) },
    }
}
</script>

<style>

</style>
