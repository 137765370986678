<template>

    <div class="w-full flex-grow flex flex-col justify-start items-center">

        <h2 class="text-2xl py-16">Why did you stop?</h2>

		<div class="flex flex-col space-y-8">
            <btn
                @click="next(option)"
                v-for="option in options" :key="option"
                :label="option"
                :selected="opportunity.damageType?.[0]?.toLowerCase?.() == option.toLowerCase() || (otherSaved && option == 'Other')"
                :dataTest="`observations-${option?.toLowerCase()}`"
            />
        </div>

        <div class="text-center mt-8">
            <button @click="next('Retail')" class="underline text-lg">
                <span>Retail</span>
            </button>
        </div>

        <Modal v-if="otherSelected" @hide="otherSelected = false">
            <h2 class="text-2xl">Reason(s) for Stop</h2>

            <div class="space-y-2">
                <div v-for="option in otherOptions" :key="option" class="flex items-center space-x-2">
                    <input type="checkbox" name="other" :id="option.replace(/[^a-zA-Z0-9\s]/g, '')?.replace(/\s/g, '-')" :value="option" v-model="other">
                    <label :for="option.replace(/[^a-zA-Z0-9\s]/g, '')?.replace(/\s/g, '-')">{{ option }}</label>
                </div>
            </div>

            <div class="w-full flex justify-end">
                <button @click="next(other)" :class="`bg-${store.theme.color}-500 border-${store.theme.color}-600`" class="text-xl px-4 py-2 rounded-xl text-white uppercase border-b-4 self-end">
                    Continue
                </button>
            </div>
        </Modal>

    </div>

</template>

<script>
import Mango from '../../helpers/mango.js'
import Modal from '../layout/modal.vue'

export default {
    props: ['opportunity'],
    inject: ['store'],
    components: { Modal },
    data() {
        return {
            otherSelected: false,
            savingOpportunity: false,
            options: [
                'Wind',
                'Hail',
                'Other'
            ],
            otherOptions: ['Missing Shingles','Old Roof','Owner Request','Possible Hail','Storm Opportunity','Tarp on Roof','Canvassing'],
            other: [],
        }
    },
    mounted() {
        if (this.opportunity.damageType?.length && !['wind','hail','retail'].includes(this.opportunity.damageType[0])) {
            // this.otherSelected = true
            this.other = this.opportunity.damageType
        }
    },
    watch: {
        savingOpportunity() {
            this.$emit('saving', this.savingOpportunity)
        }
    },
    methods: {
        async next(option) {
            if (option == 'Other') {
                this.otherSelected = true
                return
            }

            this.otherSelected = false

            let options = Array.isArray(option) ? option : [option]

            this.savingOpportunity = true
            this.opportunity.id = (await this.store.localDB.save({ id: this.opportunity.id, damageType: options }))?.id
            this.opportunity.damageType = options
            this.savingOpportunity = false

			const params = new URLSearchParams(window.location.search)
			const uri = params.has('return') ? params.get('return') : 'damage-location'
            if (options.includes('Retail')) {
                this.$router.push(`/opportunities/${this.opportunity.id}/retail`)
            } else {
                this.$router.push(`/opportunities/${this.opportunity.id}/${uri}`)
            }
        }
    },
    computed: {
        otherSaved() {
            return this.opportunity.damageType?.length && !['wind','hail','retail'].includes(this.opportunity.damageType?.[0]?.toLowerCase?.())
        }
    }
}
</script>
