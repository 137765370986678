const tasks = {
    sqToBeInstalled: {
        quantity: 1,
        unit: "SQ",
        description: "SQ to be installed",
        resolver: (estimate, materialsEstimate) => {
            let shingleQty = materialsEstimate.find(
                (e) => e.item === "shingles"
            ).quantity;
            let hipRidgeQty = materialsEstimate.find(
                (e) => e.item === "hipAndRidgeCap"
            ).quantity;
            let starterQty = materialsEstimate.find(
                (e) => e.item === "starter"
            ).quantity;
            return (shingleQty + hipRidgeQty + starterQty) / 3;
        },
    },
    sqToBeRemoved: {
        quantity: 1,
        unit: "SQ",
        description: "SQ to be removed",
        resolver: (estimate, materialsEstimate) => {
            let shingleQty = materialsEstimate.find(
                (e) => e.item === "shingles"
            ).quantity;
            let hipRidgeQty = materialsEstimate.find(
                (e) => e.item === "hipAndRidgeCap"
            ).quantity;
            let starterQty = materialsEstimate.find(
                (e) => e.item === "starter"
            ).quantity;
            return (shingleQty + hipRidgeQty + starterQty) / 3;
        },
    },
    secondStoryPerSq: {
        quantity: 1,
        unit: "SQ",
        description: "2ND STORY PER SQ",
        resolver: (estimate, materialsEstimate) => {
            return estimate.dimensions.secondStorySF
                ? estimate.dimensions.secondStorySF / 100
                : 0;
        },
    },
    extraLayers: {
        quantity: 1,
        unit: "SQ",
        description: "EXTRA LAYERS",
        resolver: (estimate, materialsEstimate) => {
            return estimate.details.extraLayers
                ? estimate.dimensions.squares * estimate.details.extraLayers
                : 0;
        },
    },
    steepFacetsSq712: {
        quantity: 1,
        unit: "SQ",
        description: "STEEP FACETS SQ 7/12",
        resolver: (estimate, materialsEstimate) => {
            return estimate.dimensions.facets7_12
                ? estimate.dimensions.facets7_12 / 100
                : 0;
        },
    },
    steepFacetsSq812: {
        quantity: 1,
        unit: "SQ",
        description: "STEEP FACETS SQ 8/12",
        resolver: (estimate, materialsEstimate) => {
            return estimate.dimensions.facets8_12
                ? estimate.dimensions.facets8_12 / 100
                : 0;
        },
    },
    steepFacetsSq912: {
        quantity: 1,
        unit: "SQ",
        description: "STEEP FACETS SQ 9/12",
        resolver: (estimate, materialsEstimate) => {
            return estimate.dimensions.facets9_12
                ? estimate.dimensions.facets9_12 / 100
                : 0;
        },
    },
    steepFacetsSq1012: {
        quantity: 1,
        unit: "SQ",
        description: "STEEP FACETS SQ 10/12",
        resolver: (estimate, materialsEstimate) => {
            return estimate.dimensions.facets10_12
                ? estimate.dimensions.facets10_12 / 100
                : 0;
        },
    },
    steepFacetsSq1112: {
        quantity: 1,
        unit: "SQ",
        description: "STEEP FACETS SQ 11/12",
        resolver: (estimate, materialsEstimate) => {
            return estimate.dimensions.facets11_12
                ? estimate.dimensions.facets11_12 / 100
                : 0;
        },
    },
    steepFacetsSq1212: {
        quantity: 1,
        unit: "SQ",
        description: "STEEP FACETS SQ 12/12",
        resolver: (estimate, materialsEstimate) => {
            return estimate.dimensions.facets12_12
                ? estimate.dimensions.facets12_12 / 100
                : 0;
        },
    },
    decking: {
        quantity: 1,
        unit: "EACH",
        description: "DECKING",
        resolver: (estimate, materialsEstimate) => {
            return estimate.details.decking ? estimate.details.decking : 0;
        },
    },
    dumpFeesHaulOff: {
        quantity: 1,
        unit: "EACH",
        description: "DUMP FEES/HAUL OFF",
        resolver: (estimate, materialsEstimate) => {
            return 0; // spreadsheet has no formula
        },
    },
    cutInRidgeVent: {
        quantity: 1,
        unit: "LF",
        description: "CUT IN RIDGE VENT",
        resolver: (estimate, materialsEstimate) => {
            return estimate.structures.newVent
                ? estimate.dimensions.ridgeVentLF
                : 0;
        },
    },
    chimneyFlashing: {
        quantity: 1,
        unit: "EACH",
        description: "CHIMNEY FLASHING",
        resolver: (estimate, materialsEstimate) => {
            return 0; // spreadsheet has no formula
        },
    },
    tripCharge: {
        quantity: 1,
        unit: "EACH",
        description: "TRIP CHARGE",
        resolver: (estimate, materialsEstimate) => {
            return estimate.details.chargedTrips
                ? estimate.details.chargedTrips
                : 0;
        },
    },
    guttersRemoved: {
        quantity: 1,
        unit: "EACH",
        description: "GUTTERS REMOVED",
        resolver: (estimate, materialsEstimate) => {
            return estimate.structures.gutters
                ? estimate.dimensions.perimeterLF
                : 0;
        },
    },
    noAccessPerSq: {
        quantity: 1,
        unit: "SQ",
        description: "NO ACCESS PER SQ",
        resolver: (estimate, materialsEstimate) => {
            return 0; // spreadsheet has no formula
        },
    },
    installCricketsEach: {
        quantity: 1,
        unit: "EACH",
        description: "INSTALL CRICKETS EACH",
        resolver: (estimate, materialsEstimate) => {
            return 0; // spreadsheet has no formula
        },
    },
    cutInCoverUpBoxVentsEach: {
        quantity: 1,
        unit: "EACH",
        description: "CUT IN/COVER UP BOX VENTS EACH",
        resolver: (estimate, materialsEstimate) => {
            return estimate.materials.ccBV ? estimate.materials.ccBV : 0;
        },
    },
    replaceSkylightFlashingInc: {
        quantity: 1,
        unit: "EACH",
        description: "REPLACE SKYLIGHT (FLASHING INC.)",
        resolver: (estimate, materialsEstimate) => {
            return estimate.structures.replaceSkylight
                ? estimate.structures.replaceSkylight
                : 0;
        },
    },
    skylightFlashingReFlash: {
        quantity: 1,
        unit: "EACH",
        description: "SKYLIGHT FLASHING (RE-FLASH)",
        resolver: (estimate, materialsEstimate) => {
            return estimate.structures.flashSkylight
                ? estimate.structures.flashSkylight
                : 0;
        },
    },
    counterFlashing: {
        quantity: 1,
        unit: "LF",
        description: "COUNTER FLASHING",
        resolver: (estimate, materialsEstimate) => {
            return estimate.materials.counterFlash
                ? estimate.materials.counterFlash
                : 0;
        },
    },
    decking1xBoardsLft: {
        quantity: 1,
        unit: "LF",
        description: "DECKING 1X BOARDS LFT",
        resolver: (estimate, materialsEstimate) => {
            return estimate.details.replaceDecking
                ? estimate.details.replaceDecking
                : 0;
        },
    },
    extraLayerFeltTearOff: {
        quantity: 1,
        unit: "SQ",
        description: "EXTRA LAYER FELT TEAR OFF",
        resolver: (estimate, materialsEstimate) => {
            return estimate.details.extFeltLayers
                ? estimate.dimensions.squares * estimate.details.extFeltLayers
                : 0;
        },
    },
    mansard: {
        quantity: 1,
        unit: "",
        description: "MANSARD",
        resolver: (estimate, materialsEstimate) => {
            return 0; // spreadsheet has no formula
        },
    },
    stepFlashing: {
        quantity: 1,
        unit: "LF",
        description: "STEP FLASHING",
        resolver: (estimate, materialsEstimate) => {
            return estimate.dimensions.stepFlash
                ? estimate.dimensions.stepFlash
                : 0;
        },
    },
};

export { tasks };
