<template>

    <div :class="`w-full h-full flex flex-col items-center justify-center space-y-16 bg-gradient-to-tr from-${store.theme.color}-100 via-${store.theme.color}-50 to-white`">
        <img :src="store.theme?.logo?.url" class="px-16 w-full" />
        <div class="px-16 text-center text-gray-600">
            We hope you've enjoyed using the {{ store.theme?.title }} platform. {{ endMessage }}. To continue enjoying these benefits, please subscribe using the button below.
        </div>
        <div class="text-5xl font-mono">$10<span class="text-2xl text-gray-500">/mo</span></div>
        <a
            :href="`https://buy.stripe.com/bIYbLJdU55Z65vq289?prefilled_email=${store.user.email}&client_reference_id=${store.user.id}`"
            :class="`px-3 py-2 bg-${store.theme.color}-400 text-white rounded-lg shadow-md hover:bg-${store.theme.color}-600 transition duration-300`"
        >
            Subscribe
        </a>

        <div class="w-full flex justify-center">
            <Login v-slot="{logout}" class="!p-0 !w-auto">
                <button class="py-1 border-b-2" @click="logout">Logout</button>
            </Login>
        </div>
    </div>

</template>

<script>
import Login from '../layout/login.vue'
export default {
  components: { Login },
    inject: ['store'],
    created() {
        if (!this.store.user?.id) {
            this.$router.push('/login')
        }
        if (this.store.user?.validSubscription) {
            this.$router.push('/')
        }
    },
    computed: {
        endMessage() {
            return this.store.user?.subscriptionExpiration ? 'Your subscription has expired' : 'Your free trial has ended'
        },
    }
}
</script>

<style>

</style>
