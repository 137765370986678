<template>
    <div class="relative">

        <input
            v-if="vague"
            v-model="address.formatted"
            type="text"
            class="px-3 py-2 border w-full bg-white dark:bg-transparent"
            :placeholder="placeholder || 'Address'"
            @input="delete address.id"
        />
        <!-- <div class="absolute bottom border-x border-b rounded-b-lg shadow-card w-full z-50 bg-white overflow-scroll" v-show="autocompleteResults.length">
            <button
                v-for="address in autocompleteResults" :key="address.id"
                class="hover:bg-sky-50 px-4 py-2 w-full text-left"
                @click="selectAddress(address)"
            >
                {{ address.formattedAddress }}
            </button>
        </div> -->

        <div v-else class="space-y-2">
            <div class="space-x-4 flex">
                <div class="w-24">
                    <div class="text-2xs mb-1 uppercase font-bold tracking-widest opacity-40">Number</div>
                    <input type="text" v-model="address.number" placeholder="Number" @input="updateAddress" data-test="number" ref="number" />
                </div>
                <div class="flex-grow">
                    <div class="text-2xs mb-1 uppercase font-bold tracking-widest opacity-40">Street</div>
                    <input type="text" v-model="address.street" placeholder="Street" @input="updateAddress" data-test="street" />
                </div>
            </div>
            <div class="space-x-4 flex">
                <div class="flex-grow">
                    <div class="text-2xs mb-1 uppercase font-bold tracking-widest opacity-40">City</div>
                    <input type="text" v-model="address.city" placeholder="City" @input="updateAddress" data-test="city" />
                </div>
                <div class="w-24">
                    <div class="text-2xs mb-1 uppercase font-bold tracking-widest opacity-40">State</div>
                    <input type="text" v-model="address.stateCode" placeholder="State" @input="updateAddress" data-test="state" />
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import normalizeAddress from '../../../../config/helpers/normalizeAddress';

let address = {
    address: null,
    city: null,
    state: null,
    zip: null,
    country: null,
    formatted: null,
    raw: null,
    number: null,
    street: null,
    coordinates: {
        lat: null,
        lng: null,
    },
}

export default {
    inject: ['store'],
    props: ['modelValue','placeholder','useLastAddress','vague'],
    data() {
        return {
            coordinates: null,
            address
        }
    },
	watch: {
        modelValue: {
            handler: function (address) {
                this.address = address
            },
            deep: true,
            immediate: true
        },
    },
    // async mounted() {
    //     if (this.useLastAddress && this.store?.lastAddress) {
    //         this.$refs.number.focus()
    //     }
    // },
    async created() {

        if (this.useLastAddress && this.store?.lastAddress) {
            this.address = {
                street: this.store.lastAddress.street,
                city: this.store.lastAddress.city,
                stateCode: this.store.lastAddress.stateCode,
            }
            // if (this.address?.number) this.address.number = ''
            // if (this.address?.id) delete this.address.id
            // if (this.address?.formatted) this.address.formatted = null
        }


        /*
            I think this was just used for autocomplete which is dead now
        */

        // let result = await navigator.permissions.query({name:'geolocation'})

        // if (result.state == 'prompt') {
        //     navigator.geolocation.getCurrentPosition(() => {});
        // } else if (result.state == 'denied') {
        //     Swal.fire({
        //         title: 'Location Required',
        //         text: 'Please enable location services to proceed.',
        //         icon: 'warning',
        //     })
        // }

        // navigator.geolocation.getCurrentPosition((position) => {
        //     this.coordinates = `${position.coords.latitude},${position.coords.longitude}`
        // })

    },
    methods: {
        updateAddress() {
            delete this.address.id
            let normalizedAddress = normalizeAddress({...this.address})
            this.address.formatted = normalizedAddress.formatted
            this.address.bcrid = normalizedAddress.bcrid
            this.$emit('update:modelValue', this.address)
        },
    },
}
</script>

<style lang="postcss" scoped>
input {
    @apply border border-gray-300 rounded outline-blue-400 dark:outline-blue-500/40 px-3 py-2 dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-600 w-full
}
</style>
