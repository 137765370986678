<template>
    <!-- <div :class="{'dark':darkMode.enabled}"> -->

    <!--

        bg-red-50
        bg-red-100
        bg-red-200
        bg-red-300
        bg-red-400
        bg-red-500
        bg-red-600
        bg-red-700
        bg-red-800
        bg-red-900

        border-red-50
        border-red-100
        border-red-200
        border-red-300
        border-red-400
        border-red-500
        border-red-600
        border-red-700
        border-red-800
        border-red-900

        from-red-100
        via-red-50

     -->
    <div class="w-full max-w-screen min-h-screen flex justify-center items-center bg-gray-500">
        <div v-if="store?.user?.level || $route.path.split('/').pop() == 'faq'" class="w-full min-h-screen bg-white font-sans border border-gray-300 flex flex-col pb-20">

            <SyncEntries />

            <RouterView v-slot="{ Component, route }" @navHidden="navHidden = $event">
				<template v-if="Component">
                    <!-- <KeepAlive> -->
					<Suspense :timeout="200" >
						<component :is="Component" :key="route.meta.usePathKey ? route.path : undefined"></component>
						<template #fallback><spinner /></template>
					</Suspense>
                    <!-- </KeepAlive> -->
				</template>
            </RouterView>

            <AppDrawer />
            <InstallPrompt />

        </div>
        <Login v-else class="bg-white h-screen" />
    </div>
</template>

<script>
import AppDrawer from './components/layout/appDrawer.vue'
import Login from './components/layout/login.vue'
import KTWC from './components/layout/ktwc.vue'
import SyncEntries from './components/layout/syncEntries.vue'
import InstallPrompt from './components/partials/installPrompt.vue'
// import Nav from './components/layout/nav.vue'

export default {
    components: { KTWC, Login, SyncEntries, AppDrawer, InstallPrompt },
    inject: ['store','darkMode'],
    data() {
        return {
            navHidden: false
        }
    }
}
</script>

<style lang="postcss">
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;700&display=swap');
/* Reset default styles for the select element */
select {
  appearance: none; /* Remove default appearance on some browsers */
  -webkit-appearance: none; /* Remove default appearance on Safari */
  -moz-appearance: none; /* Remove default appearance on Firefox */
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0px center; /* Adjust the position of the arrow */
}

/* Apply the filter utility to change SVG color in dark mode */
.dark select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgba(255, 255, 255, 0.5)' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
}
button {
    @apply select-none;
}
</style>
